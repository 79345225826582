import { orderList, orderGet, orderEdit, orderDelete } from 'api';
import { getErrorMessage } from 'utils/axios';

export const ORDER_ERROR = 'ORDER_ERROR';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';

export const ORDER_ID_REQUEST = 'ORDER_ID_REQUEST';
export const ORDER_ID_SUCCESS = 'ORDER_ID_SUCCESS';

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';

export const ORDER_STATUS_UPDATE_REQUEST = 'ORDER_STATUS_UPDATE_REQUEST';
export const ORDER_STATUS_UPDATE_SUCCESS = 'ORDER_STATUS_UPDATE_SUCCESS';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

export const loadOrders = () => async (dispatch) => {

  // Initialization
  dispatch({ type: ORDER_LIST_REQUEST });

  // Fetch and set state based on fetch result
  try {
    const res = await orderList();
    dispatch({ type: ORDER_LIST_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: ORDER_ERROR, message: getErrorMessage(error) });
  }
};

export const getOrder = (id, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: ORDER_ID_REQUEST });

  const { orders } = getStore();
  const localOrder = orders.data.find(item => item.id === id);
  if (localOrder) {
    dispatch({ type: ORDER_ID_SUCCESS, data: localOrder });
    onSuccess && onSuccess();
    return;
  }

  try {
    const res = await orderGet(id);
    dispatch({ type: ORDER_ID_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch({ type: ORDER_ERROR, message });
    onError && onError(message);
  }
};

export const bulkMarkOrdersStatus = (ids, status, handlers = defaultHandlers) => async dispatch => {
  if (ids.length < 1) {
    return;
  }

  const { onSuccess, onError } = handlers;
  dispatch({ type: ORDER_STATUS_UPDATE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => orderEdit(id, { status })));
    dispatch({ type: ORDER_STATUS_UPDATE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch({ type: ORDER_ERROR, message });
    onError && onError(message);
  }
};

export const updateOrder = (id, data, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: ORDER_UPDATE_REQUEST });

  try {
    const res = await orderEdit(id, { ...data });
    dispatch({ type: ORDER_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch({ type: ORDER_ERROR, message });
    onError && onError(message);
  }
};

export const bulkDeleteOrders = (ids, handlers = defaultHandlers) => async dispatch => {
  if (ids.length < 1) {
    return;
  }

  const { onSuccess, onError } = handlers;
  dispatch({ type: ORDER_DELETE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => orderDelete(id, { status })));
    dispatch({ type: ORDER_DELETE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch({ type: ORDER_ERROR, message });
    onError && onError(message);
  }
};
