import {
  blogList,
  blogAdd,
  blogGet,
  blogEdit,
  blogDelete
} from 'api';
import { getErrorMessage } from 'utils/axios';
import slugify from 'utils/slugify';

export const BLOG_ERROR = 'BRANDS_ERROR';

export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST';
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS';
export const BLOG_ID_REQUEST = 'BLOG_ID_REQUEST';
export const BLOG_ID_SUCCESS = 'BLOG_ID_SUCCESS';

export const BLOG_ADD_REQUEST = 'BLOG_ADD_REQUEST';
export const BLOG_ADD_SUCCESS = 'BLOG_ADD_SUCCESS';
export const BLOG_UPDATE_REQUEST = 'BLOG_UPDATE_REQUEST';
export const BLOG_UPDATE_SUCCESS = 'BLOG_UPDATE_SUCCESS';
export const BLOG_DELETE_REQUEST = 'BLOG_DELETE_REQUEST';
export const BLOG_DELETE_SUCCESS = 'BLOG_DELETE_SUCCESS';

export const BLOG_SEARCH_QUERY = 'BLOG_SEARCH_QUERY';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

export const loadBlogs = (handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { blogs } = getStore();
  const { onSuccess, onError } = handlers;
  const { query } = blogs;
  // Initialization
  dispatch({ type: BLOG_LIST_REQUEST });

  // Fetch and set state based on fetch result
  try {
    const res = await blogList(slugify(query));
    dispatch({ type: BLOG_LIST_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BLOG_ERROR, message: getErrorMessage(error) });
    onError && onError(message);
  }
};

export const addBlog = (data, handlers = defaultHandlers) => async (dispatch) => {
  const { onSuccess, onError } = handlers;

  dispatch({ type: BLOG_ADD_REQUEST });
  try {
    const res = await blogAdd({
      ...data
    });
    dispatch({ type: BLOG_ADD_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    let message = getErrorMessage(error);
    if (error.response && error.response.status === 500) {
      message = 'SKU or slug already exists';
    }
    dispatch({ type: BLOG_ERROR, message });
    onError && onError(message);
  }
};

export const searchBlogs = (query, handlers = defaultHandlers) => async (dispatch) => {
  dispatch({ type: BLOG_SEARCH_QUERY, query });
  await dispatch(loadBlogs(handlers));
};

export const getBlog = (id, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: BLOG_ID_REQUEST });

  const { categories } = getStore();
  const localCat = categories.data.find(item => item.id === id);
  if (localCat) {
    dispatch({ type: BLOG_ID_SUCCESS, data: localCat });
    onSuccess && onSuccess();
    return;
  }

  try {
    const res = await blogGet(id);
    dispatch({ type: BLOG_ID_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BLOG_ERROR, message });
    onError && onError(message);
  }
};

export const updateBlog = (id, data, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: BLOG_UPDATE_REQUEST });

  try {
    // Edit pricing and update product, can be run parallel
    const res = await blogEdit(id, data);

    dispatch({ type: BLOG_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BLOG_ERROR, message });
    onError && onError(message);
  }
};

export const bulkDeleteBlogs = (ids, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  if (ids.length < 1) {
    return;
  }
  dispatch({ type: BLOG_DELETE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => blogDelete(id)));
    dispatch({ type: BLOG_DELETE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BLOG_ERROR, message });
    onError && onError(message);
  }
};
