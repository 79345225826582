export const FILTER_OPERATORS = {
  eq: 'eq',
  ne: 'ne',
  gt: 'gt',
  lt: 'lt',
  gte: 'gte',
  lte: 'lte'
};

export const FILTER_OPTIONS = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};
