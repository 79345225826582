import * as blogActions from 'actions/blogActions';

const initialState = {
  success: false,
  pending: true,
  error: '',

  query: '',
  data: [],
  item: null,
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case blogActions.BLOG_LIST_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true
      };
    case blogActions.BLOG_SEARCH_QUERY:
      return {
        ...state,
        query: action.query
      };
    case blogActions.BLOG_ID_REQUEST:
    case blogActions.BLOG_ADD_REQUEST:
    case blogActions.BLOG_UPDATE_REQUEST:
    case blogActions.BLOG_DELETE_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        item: null
      };
    case blogActions.BLOG_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message
      };
    case blogActions.BLOG_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data
      };
    case blogActions.BLOG_ID_SUCCESS:
    case blogActions.BLOG_UPDATE_SUCCESS:
    case blogActions.BLOG_ADD_SUCCESS:
    case blogActions.BLOG_DELETE_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        item: action.data
      };
    default:
      return state;
  }
}

export default blogReducer;
