import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { ACTIVITY_TYPE } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1)
  }
}));

const ProductMetrics = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation('overview');

  const orders = useSelector(state => state.orders.data);
  const products = useSelector(state => state.products.data);
  const activities = useSelector(state => state.activities.data);

  const mostOrderedProduct = useMemo(() => {
    const productCounters = orders.reduce((dict, item) => {
      const { items } = item;
      if (!items || items.length < 1) {
        return dict;
      }
  
      const newDict = { ...dict };
      for (const line of items) {
        if (!line.product_id) {
          continue;
        }
  
        if (Object.prototype.hasOwnProperty.call(newDict, line.product_id)) {
          newDict[line.product_id] += line.quantity;
        } else {
          newDict[line.product_id] = line.quantity;
        }
      }
  
      return newDict;
    }, {});
  
    let mostOrderedProdId = '';
    let mostOrderedProdIdCount = 0;
    for (const [prodId, count] of Object.entries(productCounters)) {
      if (!mostOrderedProdId || mostOrderedProdIdCount < count) {
        mostOrderedProdId = prodId;
      }
    }

    return products.find(item => item.id === mostOrderedProdId);
  }, [products, orders]);

  const getMostByType = useCallback((activityType) => {
    const byTypeProducts = activities
      .filter(item => item.type === activityType)
      .map(item => item.product);
    
    if (!byTypeProducts.length) {
      return null;
    }
    /* Build a dictionary (object) of products historgram
    { productId: { data: productObject, count: int } }
    */
    const histogram = byTypeProducts.reduce((hist, item) => {
      if (Object.prototype.hasOwnProperty.call(hist, item.id)) {
        return {
          ...hist,
          [item.id]: {
            ...hist[item.id],
            count: hist[item.id].count + 1
          }
        }
      }

      return {
        ...hist,
        [item.id]: {
          data: item,
          count: 1
        }
      }
    }, {});

    const maxHistogram = Object.values(histogram).reduce((maxHist, item) => {
      if (item.count > maxHist.count) {
        return item;
      }

      return maxHist;
    }, { data: null, count: 0 });

    return maxHistogram.data;
  }, [activities]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <List>
        <ListItem divider>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body1">
              <span className={classes.value}>{t('metrics.mostOrderedProduct')}</span>{' '}
              {mostOrderedProduct?.title || t('metrics.nodata')}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body1">
              <span className={classes.value}>{t('metrics.mostSharedProduct')}</span>{' '}
              {getMostByType(ACTIVITY_TYPE.SHARE.code)?.title || t('metrics.nodata')}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <FavoriteIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body1">
              <span className={classes.value}>{t('metrics.mostLikedProduct')}</span>{' '}
              {getMostByType(ACTIVITY_TYPE.LIKE.code)?.title || t('metrics.nodata')}
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </Card>
  );
};

ProductMetrics.propTypes = {
  className: PropTypes.string
};

export default ProductMetrics;
