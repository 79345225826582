import { requestV2 } from 'utils/axios';
import getToken from './getToken';

const ENDPOINT = 'transactions';

export const add = (data) =>
  requestV2('post', ENDPOINT, getToken(), { data });

export const list = (params) =>
  requestV2('get', ENDPOINT, getToken(), { params });

export const get = (id) =>
  requestV2('get', `${ENDPOINT}/${id}`, getToken());
