import { request, requestV2 } from 'utils/axios';
import getToken from './getToken';

const ENDPOINT = 'product-variants';

export const list = (shopId, offset, limit, params = new URLSearchParams()) => {
  const reqParams = {
    isDeleted: false,
    _sort: 'updatedAt:desc',
    ...params
  };
  if (shopId) {
    reqParams.shop = shopId;
  }
  if (offset) {
    reqParams._start = offset;
  }
  if (limit) {
    reqParams._limit = limit;
  }

  return requestV2(
    'get', ENDPOINT, undefined,
    {
      params: reqParams
    }
  );
};

export const add = (data) =>
  request('post', ENDPOINT, getToken(), data);

export const get = (id) =>
  request('get', `${ENDPOINT}/${id}`);

export const edit = (id, data) =>
  request('put', `${ENDPOINT}/${id}`, getToken(), data);

export const remove = (id) =>
  request('delete', `${ENDPOINT}/${id}`, getToken());

export const count = (slug) =>
  request('get', `${ENDPOINT}/count?${slug ? `slug=${slug}` : ''}`);

export const countPaginate = (shopId, offset, limit, params = {}) => {
  const reqParams = {
    isDeleted: false,
    ...params
  };
  if (shopId) {
    reqParams.shop = shopId;
  }
  if (offset) {
    reqParams._start = offset;
  }
  if (limit) {
    reqParams._limit = limit;
  }

  return requestV2(
    'get', `${ENDPOINT}/count`, undefined,
    {
      params: reqParams
    }
  );
};

export const search = (slug, shopId, offset, limit) => {
  const offsetParam = offset ? `&_start=${offset}` : '';
  const limitParam = limit ? `&_limit=${limit}` : '';
  const slugParam = slug ? `&slug_contains=${slug}` : '';

  return request(
    'get',
    `${ENDPOINT}?isDeleted=false${slugParam}${offsetParam}${limitParam}${shopId ? `&shop=${shopId}` : ''}`
  );
};
