import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import placeholder from './placeholder-150x150.png';
import errPlaceholder from './noimage-150x150.png';

const useStyles = makeStyles(() => ({
  '@keyframes loadImg': {
    '0%': {
      opacity: 0.1
    },
    '100%': {
      opacity: 1
    }
  },
  root: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto'
  },
  rootOnLoad: {
    animation: '$loadImg 1000ms ease-in-out'
  }
}));

/*
  Reference:
  - https://slashgear.github.io/creating-an-image-lazy-loading-component-with-react/
  - https://dev.to/producthackers/intersection-observer-using-react-49ko
  - https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
const LazyImg = (props) => {
  const { src, alt, ...rest } = props;
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [imgSrc, setImgSrc] = useState(placeholder);
  const imgRef = useRef();

  const options = {
    threshold: 0.1,
    rootMargin: '50%'
  };

  const onLoad = () => setLoaded(true);
  const onError = () => {
    setLoaded(true);
    setImgSrc(errPlaceholder);
  }

  useEffect(() => {
    let observer;
    let didCancel = false;

    // Intersection callback fired when the element in viewport by threshold
    const observerCb = (entries) => {
      entries.forEach(entry => {
        if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
          setImgSrc(src);
        }
      });
    };

    if (imgRef.current && imgSrc === placeholder) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(observerCb, options);
        observer.observe(imgRef.current);
      } else {
        // Fallback for old browsers
        setImgSrc(src);
      }
    }

    // Cleanup
    return () => {
      didCancel = true;
      if (observer && observer.unobserve && imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [src, imgSrc, imgRef])

  return (
    <img
      className={clsx(classes.root, {
        [classes.rootOnLoad]: loaded
      })}
      ref={imgRef}
      onLoad={onLoad}
      onError={onError}
      src={imgSrc}
      alt={alt}
      {...rest}
    />
  );
};
LazyImg.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.any
};

export default LazyImg;
