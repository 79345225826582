import React from 'react';
import PropTypes from 'prop-types';
import { Paper, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-classic-custom-build';

import ImgUploader from './plugins/ImgUploader';

const useStyles = makeStyles(theme => ({
  root: props => ({
    '& .ck-editor__editable_inline': {
      height: props.height || '25vh'
    },
    '& .ck .ck-content': {
      '& p': {
        ...theme.typography.body1
      },
      '& h1': {
        ...theme.typography.h1
      },
      '& h2': {
        ...theme.typography.h2
      },
      '& h3': {
        ...theme.typography.h3
      },
      '& h4': {
        ...theme.typography.h4
      },
      '& h5': {
        ...theme.typography.h5
      },
      '& h6': {
        ...theme.typography.h6
      },
      '& blockquote': {
        ...theme.typography.subtitle1
      },
      '& ul': {
        ...theme.typography.body1,
        marginLeft: theme.spacing(4)
      },
      '& ol': {
        ...theme.typography.body1,
        marginLeft: theme.spacing(4)
      }
    }
  })
}));

const RichTextEditor = props => {
  const { className, placeholder, data, onReady, ...rest } = props;
  const classes = useStyles(props);

  const config = {
    extraPlugins: [ImgUploader],
    toolbar: {
      items: [
        'heading',
        '|',
        'alignment',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'subscript',
        'superscript',
        '|',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        'todoList',
        '-',
        'fontfamily',
        'fontsize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'insertTable',
        '|',
        'outdent',
        'indent',
        '|',
        'horizontalline',
        'insertImage',
        'blockQuote',
        'mediaEmbed',
        '|',
        'undo',
        'redo'
      ],
      shouldNotGroupWhenFull: true
    },
    image: {
      styles: ['alignLeft', 'alignCenter', 'alignRight'],
      toolbar: [
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
        '|',
        'imageTextAlternative'
      ]
    },
    placeholder
  };

  return (
    <Paper {...rest} className={clsx(classes.root, className)}>
      <CKEditor
        editor={ClassicEditor}
        onReady={onReady}
        data={data}
        config={config}
      />
    </Paper>
  );
};
RichTextEditor.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.string,
  onReady: () => {}
};

export default RichTextEditor;
