import { request } from 'utils/axios';
import getToken from './getToken';

const ENDPOINT = 'orders';

export const list = () =>
  request('get', `${ENDPOINT}?_sort=updatedAt:desc`);

export const get = (id) =>
  request('get', `${ENDPOINT}/${id}`);

export const edit = (id, data) =>
  request('put', `${ENDPOINT}/${id}`, getToken(), data);

export const remove = (id) =>
  request('delete', `${ENDPOINT}/${id}`, getToken());
