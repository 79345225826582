import { request } from 'utils/axios';
import getToken from './getToken';

const ENDPOINT = 'categories';

export const list = (shopId) => request(
  'get',
  `${ENDPOINT}?isDeleted=false&_sort=position:asc${shopId ? `&shop=${shopId}` : ''}`
);

export const get = (id) => request(
  'get',
  `${ENDPOINT}/${id}`
);

export const create = (data) => request('post', `${ENDPOINT}`, getToken(), data);

export const edit = (id, data) => request('put', `${ENDPOINT}/${id}`, getToken(), data);

export const remove = (id) => request('delete', `${ENDPOINT}/${id}`, getToken());
