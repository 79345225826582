import { request } from 'utils/axios';
import getToken from './getToken';

const ENDPOINT = 'pricings';

export const add = (data) =>
  request('post', ENDPOINT, getToken(), data);

export const edit = (id, data) =>
  request('put', `${ENDPOINT}/${id}`, getToken(), data);
