export const ORDER_STATUS = {
  IN_PROGRESS: {
    code: 'IN_PROGRESS',
    label: 'In Progress'
  },
  SHIPPED: {
    code: 'SHIPPED',
    label: 'Shipped'
  },
  DELIVERY: {
    code: 'DELIVERY',
    label: 'Delivery'
  },
  DONE: {
    code: 'DONE',
    label: 'Done'
  }
};

export const ACTIVITY_TYPE = {
  LIKE: {
    code: 'LIKE',
    label: 'Like'
  },
  COMMENT: {
    code: 'COMMENT',
    label: 'Comment'
  },
  SHARE: {
    code: 'SHARE',
    label: 'Share'
  }
};

export const PROMOTION_TYPE = {
  P_PERCENTAGE: {
    code: 'P_PERCENTAGE',
    label: 'Percentage'
  },
  P_GIFT: {
    code: 'P_GIFT',
    label: 'Gift'
  }
};

export const FILE_SORT_BY = {
  UPLOAD_DATE: 'createdAt',
  UPDATE_DATE: 'updatedAt',
  NAME: 'name'
};
