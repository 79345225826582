import {
  navigationList,
  navigationAdd,
  navigationEdit,
  navigationDelete
} from 'api';
import { getErrorMessage } from 'utils/axios';

// Operation error
export const TAGS_ERROR = 'TAGS_ERROR';
// List tags actions
export const TAGS_LIST_REQUEST = 'TAGS_LIST_REQUEST';
export const TAGS_LIST_SUCCESS = 'TAGS_LIST_SUCCESS';
// Get tag by ID actions
export const TAGS_ID_REQUEST = 'TAGS_ID_REQUEST';
export const TAGS_ID_SUCCESS = 'TAGS_ID_SUCCESS';
// Tag update actions
export const TAGS_CREATE_REQUEST = 'TAGS_CREATE_REQUEST';
export const TAGS_CREATE_SUCCESS = 'TAGS_CREATE_SUCCESS';
export const TAGS_UPDATE_REQUEST = 'TAGS_UPDATE_REQUEST';
export const TAGS_UPDATE_SUCCESS = 'TAGS_UPDATE_SUCCESS';
export const TAGS_DELETE_REQUEST = 'TAGS_DELETE_REQUEST';
export const TAGS_DELETE_SUCCESS = 'TAGS_DELETE_SUCCESS';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

export const loadTags = (handlers = defaultHandlers) => async (
  dispatch,
  getStore
) => {
  const { session } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: TAGS_ERROR, message });
    onError && onError(message);
    return;
  }
  // Initialization
  dispatch({ type: TAGS_LIST_REQUEST });

  // Fetch and set state based on fetch result
  try {
    const res = await navigationList(shop.id);
    dispatch({ type: TAGS_LIST_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: TAGS_ERROR, message });
    onError && onError(message);
  }
};

export const addTag = (data, handlers = defaultHandlers) => async (
  dispatch,
  getStore
) => {
  const { session } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: TAGS_ERROR, message });
    onError && onError(message);
    return;
  }
  // Initialization
  dispatch({ type: TAGS_CREATE_REQUEST });
  // Submit data
  try {
    const res = await navigationAdd({
      ...data,
      shop: {
        _id: shop.id
      }
    });
    dispatch({ type: TAGS_CREATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: TAGS_ERROR, message });
    onError && onError(message);
  }
};

export const updateTag = (
  id,
  data,
  handlers = defaultHandlers
) => async dispatch => {
  const { onSuccess, onError } = handlers;

  dispatch({ type: TAGS_UPDATE_REQUEST });
  try {
    const res = await navigationEdit(id, data);
    dispatch({ type: TAGS_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: TAGS_ERROR, message });
    onError && onError(message);
  }
};

export const deleteTag = (id, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;

  dispatch({ type: TAGS_DELETE_REQUEST });
  try {
    const res = await navigationDelete(id);
    dispatch({ type: TAGS_DELETE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: TAGS_ERROR, message });
    onError && onError(message);
  }
};
