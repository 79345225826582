import * as promotionActions from 'actions/promotionActions';

const initialState = {
  success: false,
  pending: true,
  error: '',

  data: [],
  item: null
};

const promotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case promotionActions.PROMOTIONS_LIST_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        data: []
      };
    case promotionActions.PROMOTIONS_ID_REQUEST:
    case promotionActions.PROMOTIONS_ADD_REQUEST:
    case promotionActions.PROMOTIONS_UPDATE_REQUEST:
    case promotionActions.PROMOTIONS_DELETE_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        item: null
      };
    case promotionActions.PROMOTIONS_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message
      };
    case promotionActions.PROMOTIONS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data
      };
    case promotionActions.PROMOTIONS_ID_SUCCESS:
    case promotionActions.PROMOTIONS_UPDATE_SUCCESS:
    case promotionActions.PROMOTIONS_ADD_SUCCESS:
    case promotionActions.PROMOTIONS_DELETE_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        item: action.data
      };
    default:
      return state;
  }
};

export default promotionReducer;
