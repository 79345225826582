import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Backdrop, CircularProgress, makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff'
  }
}));

const LoadingBackdrop = (props) => {
  const { open, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Backdrop
      className={clsx(classes.backdrop, className)}
      open={open}
      {...rest}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
LoadingBackdrop.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool
};

export default LoadingBackdrop;
