import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';

import { loadProducts, loadOrders, loadActivities,  } from 'actions';
import { Page, AuthGuard, LoadingBackdrop } from 'components';
import {
  Header,
  Statistics,
  ProductMetrics
} from './components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  statistics: {
    marginTop: theme.spacing(3)
  },
  productMetrics: {
    marginTop: theme.spacing(6)
  },
}));

const Overview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('overview');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      await Promise.all([
        dispatch(loadProducts()),
        dispatch(loadOrders()),
        dispatch(loadActivities())
      ]);

      setLoading(false);
    };

    fetch();
  }, [dispatch])

  return (
    <AuthGuard>
      <Page
        className={classes.root}
        title={t('title')}
      >
        <Header />
        <LoadingBackdrop open={loading} />

        <Statistics className={classes.statistics} />
        <ProductMetrics className={classes.productMetrics} />
      </Page>
    </AuthGuard>
  );
};

export default Overview;
