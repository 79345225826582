import {
  promotionList,
  promotionGet,
  promotionAdd,
  promotionEdit,
  promotionDelete
} from 'api';
import { getErrorMessage } from 'utils/axios';

export const PROMOTIONS_ERROR = 'BRANDS_ERROR';

export const PROMOTIONS_LIST_REQUEST = 'PROMOTIONS_LIST_REQUEST';
export const PROMOTIONS_LIST_SUCCESS = 'PROMOTIONS_LIST_SUCCESS';
export const PROMOTIONS_ID_REQUEST = 'PROMOTIONS_ID_REQUEST';
export const PROMOTIONS_ID_SUCCESS = 'PROMOTIONS_ID_SUCCESS';

export const PROMOTIONS_ADD_REQUEST = 'PROMOTIONS_ADD_REQUEST';
export const PROMOTIONS_ADD_SUCCESS = 'PROMOTIONS_ADD_SUCCESS';
export const PROMOTIONS_UPDATE_REQUEST = 'PROMOTIONS_UPDATE_REQUEST';
export const PROMOTIONS_UPDATE_SUCCESS = 'PROMOTIONS_UPDATE_SUCCESS';
export const PROMOTIONS_DELETE_REQUEST = 'PROMOTIONS_DELETE_REQUEST';
export const PROMOTIONS_DELETE_SUCCESS = 'PROMOTIONS_DELETE_SUCCESS';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

export const loadPromotions = (handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;

  // Initialization
  dispatch({ type: PROMOTIONS_LIST_REQUEST });

  // Fetch and set state based on fetch result
  try {
    const res = await promotionList();
    dispatch({ type: PROMOTIONS_LIST_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PROMOTIONS_ERROR, message: getErrorMessage(error) });
    onError && onError(message);
  }
};

export const addPromotion = (data, handlers = defaultHandlers) => async (
  dispatch
) => {
  const { onSuccess, onError } = handlers;

  dispatch({ type: PROMOTIONS_ADD_REQUEST });
  try {
    const res = await promotionAdd({
      ...data
    });
    dispatch({ type: PROMOTIONS_ADD_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    let message = getErrorMessage(error);
    if (error.response && error.response.status === 500) {
      message = 'SKU or slug already exists';
    }
    dispatch({ type: PROMOTIONS_ERROR, message });
    onError && onError(message);
  }
};

export const getPromotion = (id, handlers = defaultHandlers) => async (
  dispatch,
  getStore
) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: PROMOTIONS_ID_REQUEST });

  const { categories } = getStore();
  const localPromotion = categories.data.find(item => item.id === id);
  if (localPromotion) {
    dispatch({ type: PROMOTIONS_ID_SUCCESS, data: localPromotion });
    onSuccess && onSuccess();
    return;
  }

  try {
    const res = await promotionGet(id);
    dispatch({ type: PROMOTIONS_ID_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PROMOTIONS_ERROR, message });
    onError && onError(message);
  }
};

export const updatePromotion = (
  id,
  data,
  handlers = defaultHandlers
) => async dispatch => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: PROMOTIONS_UPDATE_REQUEST });

  try {
    // Edit pricing and update product, can be run parallel
    const res = await promotionEdit(id, data);

    dispatch({ type: PROMOTIONS_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PROMOTIONS_ERROR, message });
    onError && onError(message);
  }
};

export const bulkDeletePromotions = (
  ids,
  handlers = defaultHandlers
) => async dispatch => {
  const { onSuccess, onError } = handlers;
  if (ids.length < 1) {
    return;
  }
  dispatch({ type: PROMOTIONS_DELETE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => promotionDelete(id)));
    dispatch({ type: PROMOTIONS_DELETE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PROMOTIONS_ERROR, message });
    onError && onError(message);
  }
};
