import * as transactionActions from 'actions/transactionActions';

const initialState = {
  success: false,
  pending: true,
  error: '',

  data: [],
  item: null,
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case transactionActions.TRANSACTION_LIST_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        data: []
      };
    case transactionActions.TRANSACTION_ID_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        item: null,
      };
    case transactionActions.TRANSACTION_ADD_REQUEST:
      return {
        ...state,
        success: false,
        pending: true,
      };
    case transactionActions.TRANSACTION_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message
      };
    case transactionActions.TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data
      };
    case transactionActions.TRANSACTION_ID_SUCCESS:
    case transactionActions.TRANSACTION_ADD_SUCCESS:
      return {
        ...state,
        sucess: true,
        pending: false,
        item: action.data
      };
    default:
      return state;
  }
}

export default transactionReducer;
