import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { loadState } from 'utils/localStorage';

const getCurrentLanguage = () => {
  const { session } = loadState();
  if (!session) {
    return 'vi';
  }

  return session.lang;
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'vi'],
    lng: getCurrentLanguage(),
    fallbackLng: 'en',
    // debug: true
  });

export default i18n;