import { shopEdit, shopGet } from 'api';
import { getErrorMessage } from 'utils/axios';

export const SESSION_ERROR = 'SESSION_ERROR';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

export const SESSION_SHOP_UPDATE_REQUEST = 'SESSION_SHOP_UPDATE_REQUEST';
export const SESSION_SHOP_UPDATE_SUCCESS = 'SESSION_SHOP_UPDATE_SUCCESS';

export const SESSION_SHOP_CHANGE = 'SESSION_SHOP_CHANGE';

export const SESSION_LANGUAGE_CHANGE = 'SESSION_LANGUAGE_CHANGE';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

export const login = (jwt, user) => dispatch =>
  dispatch({
    type: SESSION_LOGIN,
    data: {
      jwt, user
    }
  });

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT
  });

export const updateShop = (id, data, handlers = defaultHandlers) => async dispatch => {
  const { onError, onSuccess } = handlers;
  dispatch({ type: SESSION_SHOP_UPDATE_REQUEST });

  try {
    const res = await shopEdit(id, data);
    dispatch({ type: SESSION_SHOP_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error)  {
    const message = getErrorMessage(error);
    dispatch({ type: SESSION_ERROR, message });
    onError && onError(message);
  }
};

export const changeShop = (id) => async dispatch => {
  dispatch({
    type: SESSION_SHOP_CHANGE,
    id
  });

  dispatch(refreshCurrentShop());
};

export const refreshCurrentShop = () => async (dispatch, getStore) => {
  const { session } = getStore();
  const { shopIndex, shops } = session;

  // If the shop not found, something is wrong, dispatch as logout
  const currentShop = shops?.[shopIndex];
  if (!currentShop) {
    dispatch({ type: SESSION_LOGOUT, message: 'Missing shop' });
    return;
  }

  dispatch({ type: SESSION_SHOP_UPDATE_REQUEST });
  try {
    const res = await shopGet(currentShop.id);
    dispatch({ type: SESSION_SHOP_UPDATE_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: SESSION_ERROR, message: getErrorMessage(error) });
  }
};

export const changeLanguage = (language = 'en') => (dispatch) => {
  dispatch({ type: SESSION_LANGUAGE_CHANGE, language });
};
