import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import tagReducer from './tagReducer';
import brandsReducer from './brandsReducer';
import categoriesReducer from './categoriesReducer';
import productReducer from './productReducer';
import fileReducer from './fileReducer';
import catalogReducer from './catalogReducer';
import orderReducer from './orderReducer';
import activityReducer from './activityReducer';
import promotionReducer from './promotionReducer';
import blogReducer from './blogReducer';
import transactionReducer from './transactionReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  tags: tagReducer,
  brands: brandsReducer,
  categories: categoriesReducer,
  products: productReducer,
  files: fileReducer,
  catalogs: catalogReducer,
  orders: orderReducer,
  activities: activityReducer,
  promotions: promotionReducer,
  blogs: blogReducer,
  transactions: transactionReducer
});

export default rootReducer;
