/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, Paper, Avatar, Typography, TextField, MenuItem, Switch } from '@material-ui/core';
import { Hidden } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import { changeLanguage, changeShop } from 'actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  shopSelect: {
    marginTop: theme.spacing(2)
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  },
  langSwitcher: {
    marginTop: theme.spacing(1)
  }
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('navbar');

  const session = useSelector(state => state.session);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);


  const shopIndex = session.shopIndex;
  const currentShop = shopIndex >= 0 ? session.shops[shopIndex].id || '' : '';

  const handleShopChange = (event) => {
    const { value } = event.target;

    dispatch(changeShop(value));
  };

  const handleLanguageChange = (event) => {
    const { checked } = event.target;
    dispatch(changeLanguage(checked ? 'vi' : 'en'));
    i18n.changeLanguage(checked ? 'vi' : 'en');
  }

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
        />
        <Typography
          className={classes.name}
          variant="h4"
        >
          {session.user?.username}
        </Typography>
        <Typography variant="body2">{session.user?.email}</Typography>
        <div className={classes.shopSelect}>
          <Typography
            variant="h6"
            align="center"
          >
            {t('currentShop')}
          </Typography>
          <TextField
            select
            variant="outlined"
            size="small"
            style={{
              backgroundColor: 'white'
            }}
            value={currentShop}
            onChange={handleShopChange}
          >
            {session.shops.map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.langSwitcher}>
          <span>
            🇺🇸
          </span>
          <Switch
            checked={i18n.language === 'vi'}
            onChange={handleLanguageChange}
          />
          <span>
            🇻🇳
          </span>
        </div>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            // Map into object with title (and label Node if needed)
            pages={list.pages.map(page => ({ ...page, title: t(`menu.${page.key}`) }))}
            title={t('title')}
          />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
