import * as orderActions from 'actions/orderActions';

const initialState = {
  success: false,
  pending: true,
  error: '',
  // All orders data
  data: [],
  item: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case orderActions.ORDER_LIST_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        data: []
      };
    case orderActions.ORDER_ID_REQUEST:
    case orderActions.ORDER_STATUS_UPDATE_REQUEST:
    case orderActions.ORDER_UPDATE_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        item: null,
      };
    case orderActions.ORDER_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message
      };
    case orderActions.ORDER_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data
      };
    case orderActions.ORDER_ID_SUCCESS:
    case orderActions.ORDER_STATUS_UPDATE_SUCCESS:
    case orderActions.ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        sucess: true,
        pending: false,
        item: action.data
      };
    default:
      return state;
  }
}

export default orderReducer;
