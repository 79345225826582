// Reference: https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (!serializedState) {
      return {};
    }

    return JSON.parse(serializedState);
  } catch (error) {
    // Uncomment if needed
    // console.log(error);
    return {};
  }
};
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    // Uncomment if needed
    // console.log(error);
  }
};