import { requestV2 } from 'utils/axios';
import getToken from './getToken';

const ENDPOINT = 'blogs';

export const list = (slug_contains, parameters = {}) => {
  const params = {
    ...parameters
  };
  if (slug_contains) {
    params.slug_contains = slug_contains;
  }

  return requestV2('get', ENDPOINT, undefined, { params });
}

export const get = (id) =>
  requestV2('get', `${ENDPOINT}/${id}`);

export const add = (data) =>
  requestV2('post', `${ENDPOINT}`, getToken(), { data });

export const edit = (id, data) =>
  requestV2('put', `${ENDPOINT}/${id}`, getToken(), { data });

export const remove = (id) =>
  requestV2('delete', `${ENDPOINT}/${id}`, getToken());
