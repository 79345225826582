import { request, requestV2 } from 'utils/axios';
import getToken from './getToken';

const ENDPOINT = 'upload';
const ENDPOINT_FILE = 'upload/files'

export const list = (offset, limit, parameters = {}) => {
  const params = {
    ...parameters,
    _limit: -1
  };
  if (offset) {
    params._start = offset;
  }
  if (limit) {
    params._limit = limit;
  }

  return requestV2('get', ENDPOINT_FILE, getToken(), { params });
};

export const upload = (data) =>
  request('post', ENDPOINT, getToken(), data, { 'Content-Type': 'multipart/form-data' });

export const get = (id) =>
  request('get', `${ENDPOINT_FILE}/${id}`, getToken());

export const remove = (id) =>
  request('delete', `${ENDPOINT_FILE}/${id}`, getToken());

export const count = (parameters = {}) =>
  requestV2('get', `${ENDPOINT_FILE}/count`, getToken(), { params: parameters });
