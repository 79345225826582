import * as tagActions from 'actions/tagActions';

const initialState = {
  success: false,
  pending: true,
  error: '',
  // All tags data
  data: [],
  // Currently selected tag
  item: null,
};

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    // List tags reducers
    case tagActions.TAGS_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message,
      }
    case tagActions.TAGS_LIST_REQUEST:
    case tagActions.TAGS_ID_REQUEST:
    case tagActions.TAGS_CREATE_REQUEST:
    case tagActions.TAGS_UPDATE_REQUEST:
    case tagActions.TAGS_DELETE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case tagActions.TAGS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data
      };
    case tagActions.TAGS_ID_SUCCESS:
    case tagActions.TAGS_CREATE_SUCCESS:
    case tagActions.TAGS_UPDATE_SUCCESS:
    case tagActions.TAGS_DELETE_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        item: action.data
      }
    default:
      return state;
  }
};

export default tagReducer;
