import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles(() => ({
  root: {}
}));

const ConfirmDialog = (props) => {
  const { open, onConfirm, onCancel, title, children, className, ...rest } = props;

  const classes = useStyles()

  return (
    <Dialog
      {...rest}
      className={clsx(classes.root, className)}
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
ConfirmDialog.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {}
};

export default ConfirmDialog;
