import {
  brandsList,
  brandsAdd,
  brandsGet,
  brandsEdit,
  brandsDelete
} from 'api';
import { getErrorMessage } from 'utils/axios';

export const BRANDS_ERROR = 'BRANDS_ERROR';

export const BRANDS_LIST_REQUEST = 'BRANDS_LIST_REQUEST';
export const BRANDS_LIST_SUCCESS = 'BRANDS_LIST_SUCCESS';
export const BRANDS_ID_REQUEST = 'BRANDS_ID_REQUEST';
export const BRANDS_ID_SUCCESS = 'BRANDS_ID_SUCCESS';

export const BRANDS_ADD_REQUEST = 'BRANDS_ADD_REQUEST';
export const BRANDS_ADD_SUCCESS = 'BRANDS_ADD_SUCCESS';
export const BRANDS_UPDATE_REQUEST = 'BRANDS_UPDATE_REQUEST';
export const BRANDS_UPDATE_SUCCESS = 'BRANDS_UPDATE_SUCCESS';
export const BRANDS_DELETE_REQUEST = 'BRANDS_DELETE_REQUEST';
export const BRANDS_DELETE_SUCCESS = 'BRANDS_DELETE_SUCCESS';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

export const loadBrands = (handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { session } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: BRANDS_ERROR, message });
    onError && onError(message);
    return;
  }
  // Initialization
  dispatch({ type: BRANDS_LIST_REQUEST });

  // Fetch and set state based on fetch result
  try {
    const res = await brandsList(shop.id);
    dispatch({ type: BRANDS_LIST_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BRANDS_ERROR, message: getErrorMessage(error) });
    onError && onError(message);
  }
};

export const addBrand = (data, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { session } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: BRANDS_ERROR, message });
    onError && onError(message);
    return;
  }

  dispatch({ type: BRANDS_ADD_REQUEST });
  try {
    const res = await brandsAdd({
      ...data,
      shop: {
        _id: shop.id
      }
    });
    dispatch({ type: BRANDS_ADD_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    let message = getErrorMessage(error);
    if (error.response && error.response.status === 500) {
      message = 'SKU or slug already exists';
    }
    dispatch({ type: BRANDS_ERROR, message });
    onError && onError(message);
  }
};

export const getBrand = (id, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: BRANDS_ID_REQUEST });

  const { categories } = getStore();
  const localCat = categories.data.find(item => item.id === id);
  if (localCat) {
    dispatch({ type: BRANDS_ID_SUCCESS, data: localCat });
    onSuccess && onSuccess();
    return;
  }

  try {
    const res = await brandsGet(id);
    dispatch({ type: BRANDS_ID_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BRANDS_ERROR, message });
    onError && onError(message);
  }
};

export const updateBrand = (id, data, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: BRANDS_UPDATE_REQUEST });

  try {
    // Edit pricing and update product, can be run parallel
    const res = await brandsEdit(id, data);

    dispatch({ type: BRANDS_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BRANDS_ERROR, message });
    onError && onError(message);
  }
};

export const bulkDeleteBrands = (ids, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  if (ids.length < 1) {
    return;
  }
  dispatch({ type: BRANDS_DELETE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => brandsDelete(id)));
    dispatch({ type: BRANDS_DELETE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BRANDS_ERROR, message });
    onError && onError(message);
  }
};

export const bulkMarkBrands = (ids, status, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  if (ids.length < 1) {
    return;
  }

  dispatch({ type: BRANDS_UPDATE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => brandsEdit(id, { isVisible: Boolean(status) })));
    dispatch({ type: BRANDS_UPDATE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: BRANDS_ERROR, message });
    onError && onError(message);
  }
};
