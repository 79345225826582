import * as categoriesActions from 'actions/categoriesActions';

const initialState = {
  success: false,
  pending: true,
  error: '',
  // All categories data
  data: [],
  item: null,
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoriesActions.CATEGORIES_LIST_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        data: []
      };
    case categoriesActions.CATEGORIES_ID_REQUEST:
    case categoriesActions.CATEGORIES_ADD_REQUEST:
    case categoriesActions.CATEGORIES_DELETE_REQUEST:
    case categoriesActions.CATEGORIES_UPDATE_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        item: null
      };
    case categoriesActions.CATEGORIES_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message
      };
    case categoriesActions.CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data
      }
    case categoriesActions.CATEGORIES_ID_SUCCESS:
    case categoriesActions.CATEGORIES_ADD_SUCCESS:
    case categoriesActions.CATEGORIES_UPDATE_SUCCESS:
    case categoriesActions.CATEGORIES_DELETE_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        item: action.data
      };
    default:
      return state;
  }
}

export default categoriesReducer;
