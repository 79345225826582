import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Modal,
  Card,
  makeStyles, colors,
  CardActions, Button,
  CardHeader,
  CardContent,
  GridList, GridListTile, GridListTileBar,
  IconButton,
  Typography,
  LinearProgress
} from '@material-ui/core';
import {
  RadioButtonUnchecked as UnselectedIcon,
  CheckCircle as SelectedIcon
} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';

import Paginate from 'components/Paginate';
import LazyImg from 'components/LazyImg';
import ConfirmDialog from 'components/ConfirmDialog';
import { loadFiles, changeFilePage, uploadFiles, bulkDeleteFiles } from 'actions';
import { FiltersList, Toolbar } from './components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: '75vw',
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  header: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  toolbar: {
    marginBottom: theme.spacing(2)
  },
  filtersList: {
    marginBottom: theme.spacing(2)
  },
  gridList: {
    height: '55vh',
  },
  gridListTile: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgb(51, 55, 64)'
  },
  paginate: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  actions: {
    paddingTop: 0,
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  checkIcon: {
    color: colors.lightGreen.A400
  }
}));

const API_URL = process.env.REACT_APP_API_ADDR;

const ImagesSelector = (props) => {
  const {
    open, onClose, onSave,
    multiple, selectedImgs,
    className, ...rest
  } = props;
  if (!open) {
    return null;
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  // Note: Misleading name, it contains list of objects, not just id string
  const [selectedIds, setSelectedIds] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setSelectedIds(selectedImgs);
  }, [selectedImgs]);

  useEffect(() => {
    if (open) {
      dispatch(loadFiles());
    }
  }, [dispatch, open]);

  const files = useSelector(state => state.files);
  const imgs = files.data;

  const handleSelectItem = (fileObj) => {
    if (!multiple) {
      if (selectedIds.length < 1 || selectedIds[0].id !== fileObj.id) {
        setSelectedIds([fileObj]);
      } else {
        setSelectedIds([]);
      }
    } else {
      const index = selectedIds.findIndex(item => item.id === fileObj.id);
      if (index < 0) {
        setSelectedIds(selected => [...selected, fileObj]);
      } else {
        setSelectedIds(selected => selected.slice(0, index).concat(selected.slice(index + 1)));
      }
    }
  };

  const handleUploadFiles = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { files } = event.target;
    if (!files || files.length < 1) {
      return;
    }

    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }

    await dispatch(uploadFiles(formData));
    dispatch(loadFiles());
  };
  const handleDeleteFiles = async () => {
    if (selectedIds.length < 1) {
      return;
    }

    await dispatch(bulkDeleteFiles(selectedIds.map(item => item.id)));
    setSelectedIds([]);
    setOpenDelete(false);
    dispatch(loadFiles());
  };

  const handlePageChange = ({ selected }) => {
    dispatch(changeFilePage(selected));
  };
  const handleSave = () => onSave(selectedIds);

  // Push all selected images objects to top
  // Only render when page changes (i.e imgs list change)
  const sortedImgs = useMemo(() => {
    const remainImgs = imgs.filter(item => !selectedIds.find(el => el.id === item.id));

    return [...selectedIds, ...remainImgs];
  }, [imgs]);

  const totalPages = Math.ceil(files.totalItems / files.pageSize);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardHeader title="Images selector" />
          <CardContent>
            <div className={classes.header}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => inputRef.current.click()}
              >
                {t('imageSelect.button.upload')}
              </Button>
              <input
                type="file"
                hidden
                multiple={multiple}
                onChange={handleUploadFiles}
                ref={inputRef}
              />
            </div>
            <Toolbar className={classes.toolbar} />
            <FiltersList className={classes.filtersList} />
            {files.pending && (
              <LinearProgress color="primary" />
            )}
            <GridList
              cellHeight={150}
              className={classes.gridList}
              cols={8}
              spacing={8}
            >
              {sortedImgs.map(item => {
                let image = item;
                const thumbnail = item.formats?.thumbnail;
                if (thumbnail) {
                  image = thumbnail;
                }
                return (
                  <GridListTile
                    key={item.id}
                    classes={{
                      tile: classes.gridListTile
                    }}
                    cols={2}
                  >
                    <LazyImg
                      src={`${API_URL}${image.url}`}
                      alt={item.name}
                    />
                    <GridListTileBar
                      title={item.name}
                      subtitle={`Size: ${image.size}KB`}
                      actionIcon={
                        <IconButton
                          className={classes.checkIcon}
                          onClick={() => handleSelectItem(item)}
                        >
                          {selectedIds.find(el => el.id === item.id) ? <SelectedIcon /> : <UnselectedIcon />}
                        </IconButton>
                      }
                    />
                  </GridListTile>
                );
              })}
            </GridList>
            <div className={classes.paginate}>
              <Paginate
                onPageChange={handlePageChange}
                pageCount={totalPages}
                pageRangeDisplayed={2}
                // Force to use page value from state
                forcePage={files.page}
              />
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              {t('button.close')}
            </Button>
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={() => {
                if (selectedIds.length > 0) {
                  setOpenDelete(true);
                }
              }}
            >
              {t('button.delete')}
            </Button>
            <Button
              className={classes.saveButton}
              onClick={handleSave}
              variant="contained"
            >
              {t('button.save')}
            </Button>
          </CardActions>
        </Card>
      </Modal>
      <ConfirmDialog
        title={t('dialog.delete.title', { count: selectedIds.length })}
        open={selectedIds.length > 0 && openDelete}
        onCancel={() => setOpenDelete(false)}
        onConfirm={handleDeleteFiles}
      >
        <Typography
          variant="body1"
          color="textPrimary"
        >
          {t('dialog.delete.message', { count: selectedIds.length })}
        </Typography>
      </ConfirmDialog>
    </>
  );
};

ImagesSelector.propTypes = {
  className: PropTypes.string,
  selectedImgs: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  multiple: PropTypes.bool
};
ImagesSelector.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  selectedImgs: []
};

export default ImagesSelector;
