import {
  productList, productAdd, productGet, productEdit, productDelete,
  pricingAdd, pricingEdit,
  productPaginateCount
} from 'api';
import { getErrorMessage } from 'utils/axios';
import slugify from 'utils/slugify';

export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';

export const PRODUCT_ID_REQUEST = 'PRODUCT_ID_REQUEST';
export const PRODUCT_ID_SUCCESS = 'PRODUCT_ID_SUCCESS';

export const PRODUCT_ADD_REQUEST = 'PRODUCT_ADD_REQUEST';
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';

export const PRODUCT_CHANGE_PAGE = 'PRODUCT_CHANGE_PAGE';
export const PRODUCT_CHANGE_PAGE_SIZE = 'PRODUCT_CHANGE_PAGE_SIZE';

export const PRODUCT_SEARCH_QUERY = 'PRODUCT_SEARCH_QUERY';
export const PRODUCT_SET_BRAND_FILTER = 'PRODUCT_SET_BRAND_FILTER';
export const PRODUCT_SET_CATEGORY_FILTER = 'PRODUCT_SET_CATEGORY_FILTER';
export const PRODUCT_SET_CATALOG_FILTER = 'PRODUCT_SET_CATALOG_FILTER';
export const PRODUCT_SET_TAG_FILTER = 'PRODUCT_SET_TAG_FILTER';
export const PRODUCT_SET_PRICE_FILTER = 'PRODUCT_SET_PRICE_FILTER';
export const PRODUCT_CLEAR_FILTER = 'PRODUCT_CLEAR_FILTER';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
}

// Use pagination, current page, page size, product counts,...
export const loadProducts = (handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { session, products } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
    return;
  }

  // Init
  dispatch({ type: PRODUCT_LIST_REQUEST });

  // Get search query
  const { query, page, pageSize, filter } = products;
  const params = {};
  const { brands, categories, catalogs, tags, price } = filter;
  if (query) {
    params.slug_contains = slugify(query);
  }
  // Load a bunch of filter options
  if (brands.length > 0) {
    params.brand_in = brands.map(item => item.id);
  }
  if (categories.length > 0) {
    params.category_in = categories.map(item => item.id);
  }
  if (catalogs.length > 0) {
    params.catalog_in = catalogs.map(item => item.id);
  }
  if (tags.length > 0) {
    params.tags_in = tags.map(item => item.id);
  }
  const [priceMin, priceMax] = price;
  if (priceMin > 0) {
    params['pricing.sale_price_gte'] = priceMin;
  }
  if (priceMax > 0) {
    params['pricing.sale_price_lte'] = priceMax;
  }

  try {
    const [countRes, productRes] = await Promise.all([
      productPaginateCount(shop.id, page, pageSize, params),
      productList(shop.id, page, pageSize, params)
    ]);
    dispatch({ type: PRODUCT_LIST_SUCCESS, data: productRes.data, count: countRes.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
  }
};

export const changePage = (page, handlers = defaultHandlers) => async (dispatch) => {
  dispatch({ type: PRODUCT_CHANGE_PAGE, page });
  await dispatch(loadProducts(handlers));
};
export const changePageSize = (size, handlers = defaultHandlers) => async (dispatch) => {
  dispatch({ type: PRODUCT_CHANGE_PAGE_SIZE, pageSize: size });
  await dispatch(loadProducts(handlers));
};
export const searchProducts = (query, handlers = defaultHandlers) => async (dispatch) => {
  dispatch({ type: PRODUCT_SEARCH_QUERY, query });
  dispatch({ type: PRODUCT_CHANGE_PAGE, page: 0 });
  await dispatch(loadProducts(handlers));
};
export const applyProductFilterBrands = (brands = []) => dispatch => {
  dispatch({ type: PRODUCT_SET_BRAND_FILTER, brands });
};
export const applyProductFilterCategories = (categories = []) => dispatch => {
  dispatch({ type: PRODUCT_SET_CATEGORY_FILTER, categories });
};
export const applyProductFilterCatalogs = (catalogs = []) => dispatch => {
  dispatch({ type: PRODUCT_SET_CATALOG_FILTER, catalogs });
};
export const applyProductFilterTags = (tags = []) => dispatch => {
  dispatch({ type: PRODUCT_SET_TAG_FILTER, tags });
};
export const applyProductFilterPrice = (minValue, maxValue) => dispatch => {
  dispatch({ type: PRODUCT_SET_PRICE_FILTER, minValue, maxValue });
};
export const clearProductFilter = () => dispatch => {
  dispatch({ type: PRODUCT_CLEAR_FILTER });
};

export const addProduct = (data, pricing, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { session } = getStore();

  const { onSuccess, onError } = handlers

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
    return;
  }

  dispatch({ type: PRODUCT_ADD_REQUEST });
  try {
    // Add pricing
    const pricingRes = await pricingAdd(pricing);
    // Then add product with pricing id
    const productRes = await productAdd({
      ...data,
      pricing: {
        _id: pricingRes.data.id
      },
      shop: {
        _id: shop.id
      }
    });
    dispatch({ type: PRODUCT_ADD_SUCCESS, data: productRes.data });
    onSuccess && onSuccess();
  } catch (error) {
    let message = getErrorMessage(error);
    if (error.response && error.response.status === 500) {
      message = 'SKU or slug already exists';
    }
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
  }
};

export const getProduct = (id, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: PRODUCT_ID_REQUEST });

  const { products } = getStore();
  const localProduct = products.data.find(item => item.id === id);
  if (localProduct) {
    dispatch({ type: PRODUCT_ID_SUCCESS, data: localProduct });
    onSuccess && onSuccess();
    return;
  }

  try {
    const res = await productGet(id);
    dispatch({ type: PRODUCT_ID_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
  }
};

export const updateProduct = (id, data, pricingId, pricing, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: PRODUCT_UPDATE_REQUEST });

  try {
    // Edit pricing and update product, can be run parallel
    const res = await Promise.all([
      pricingEdit(pricingId, pricing),
      productEdit(id, data)
    ]);

    dispatch({ type: PRODUCT_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
  }
};

export const deleteProducts = (ids, handlers = defaultHandlers) => async dispatch => {
  if (ids.length < 1) {
    return;
  }
  const { onSuccess, onError } = handlers;
  dispatch({ type: PRODUCT_DELETE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => productDelete(id)));
    dispatch({ type: PRODUCT_DELETE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
  }
};

export const bulkMarkProductShow = (ids, handlers = defaultHandlers) => async dispatch => {
  if (ids.length < 1) {
    return;
  }

  const { onSuccess, onError } = handlers;
  dispatch({ type: PRODUCT_UPDATE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => productEdit(id, { isVisible: true })));
    dispatch({ type: PRODUCT_UPDATE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
  }
};

export const bulkMarkProductHide = (ids, handlers = defaultHandlers) => async dispatch => {
  if (ids.length < 1) {
    return;
  }

  const { onSuccess, onError } = handlers;
  dispatch({ type: PRODUCT_UPDATE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => productEdit(id, { isVisible: false })));
    dispatch({ type: PRODUCT_UPDATE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: PRODUCT_ERROR, message });
    onError && onError(message);
  }
};
