/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
/* import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault'; */
import OverviewView from './views/Overview';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/overview" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/advertise',
        exact: true,
        component: lazy(() => import('views/AdvertiseManagement'))
      },
      {
        path: '/advertise/:tab',
        exact: true,
        component: lazy(() => import('views/AdvertiseManagement'))
      },
      {
        path: '/blog',
        exact: true,
        component: lazy(() => import('views/BlogManagementList'))
      },
      {
        path: '/blog/create',
        exact: true,
        render: (props) => {
          const BlogManagementDetails = lazy(() => import('views/BlogManagementDetail'));
          return <BlogManagementDetails {...props} create />
        }
      },
      {
        path: '/blog/:id',
        exact: true,
        component: lazy(() => import('views/BlogManagementDetail'))
      },
      {
        path: '/brands',
        exact: true,
        component: lazy(() => import('views/BrandsManagementList'))
      },
      {
        path: '/brands/create',
        exact: true,
        render: (props) => {
          const BrandsManagementDetails = lazy(() => import('views/BrandsManagementDetails'));
          return <BrandsManagementDetails {...props} create />
        }
      },
      {
        path: '/brands/:id',
        exact: true,
        component: lazy(() => import('views/BrandsManagementDetails'))
      },
      /* {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('views/Calendar'))
      }, */
      {
        path: '/catalog',
        exact: true,
        component: lazy(() => import('views/CatalogManagementList'))
      },
      {
        path: '/catalog/create',
        exact: true,
        render: (props) => {
          const CatalogManagementDetails = lazy(() => import('views/CatalogManagementDetails'));
          return <CatalogManagementDetails {...props} create />
        }
      },
      {
        path: '/catalog/:id',
        exact: true,
        component: lazy(() => import('views/CatalogManagementDetails'))
      },
      {
        path: '/categories',
        exact: true,
        component: lazy(() => import('views/CategoriesManagementList'))
      },
      {
        path: '/categories/create',
        exact: true,
        render: (props) => {
          const CategoriesManagementDetails = lazy(() => import('views/CategoriesManagementDetails'));
          return <CategoriesManagementDetails {...props} create />
        }
      },
      {
        path: '/categories/:id',
        exact: true,
        component: lazy(() => import('views/CategoriesManagementDetails'))
      },
      /* {
        path: '/chat',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('views/InvoiceDetails'))
      },
      {
        path: '/kanban-board',
        exact: true,
        component: lazy(() => import('views/KanbanBoard'))
      },
      {
        path: '/mail',
        exact: true,
        component: lazy(() => import('views/Mail'))
      },
      {
        path: '/management/customers',
        exact: true,
        component: lazy(() => import('views/CustomerManagementList'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        component: lazy(() => import('views/ProjectManagementList'))
      }, */
      {
        path: '/orders',
        exact: true,
        component: lazy(() => import('views/OrderManagementList'))
      },
      {
        path: '/orders/:id',
        exact: true,
        component: lazy(() => import('views/OrderManagementDetails'))
      },
      {
        path: '/navigation',
        exact: true,
        component: lazy(() => import('views/Navigation'))
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/product',
        exact: true,
        component: lazy(() => import('views/ProductManagementList'))
      },
      {
        path: '/product/create',
        exact: true,
        render: (props) => {
          const ProductManagementDetail = lazy(() => import('views/ProductManagementDetail'));
          return <ProductManagementDetail {...props} create />
        }
      },
      {
        path: '/product/:id',
        exact: true,
        component: lazy(() => import('views/ProductManagementDetail'))
      },
      {
        path: '/promotions',
        exact: true,
        component: lazy(() => import('views/PromotionsManagementList'))
      },
      {
        path: '/promotions/create',
        exact: true,
        render: (props) => {
          const PromotionsManagementDetails = lazy(() => import('views/PromotionsManagementDetails'));
          return <PromotionsManagementDetails {...props} create />
        }
      },
      {
        path: '/promotions/:id',
        exact: true,
        component: lazy(() => import('views/PromotionsManagementDetails'))
      },
      {
        path: '/message',
        exact: true,
        component: lazy(() => import('views/MessageManagementList'))
      },
      {
        path: '/message/send',
        exact: true,
        render: (props) => {
          const MessageManagementDetails = lazy(() => import('views/MessageManagementDetails'));
          return <MessageManagementDetails {...props} compose />
        }
      },
      {
        path: '/message/:id',
        exact: true,
        component: lazy(() => import('views/MessageManagementDetails'))
      },
      /* {
        path: '/profile/:id',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/projects/create',
        exact: true,
        component: lazy(() => import('views/ProjectCreate'))
      },
      {
        path: '/projects/:id',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects',
        exact: true,
        component: lazy(() => import('views/ProjectList'))
      }, */
      {
        path: '/shop',
        exact: true,
        component: lazy(() => import('views/Shop'))
      },
      {
        path: '/shop/:tab',
        exact: true,
        component: lazy(() => import('views/Shop'))
      },
      /* {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/social-feed',
        exact: true,
        component: lazy(() => import('views/SocialFeed'))
      }, */
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
