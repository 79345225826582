import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { renderRoutes } from 'react-router-config';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import { loadInterceptor } from 'utils/axios';
import palette from 'theme/palette';
import typography from 'theme/typography';
import overrides from 'theme/overrides';
import { loadState, saveState } from 'utils/localStorage';
import defaultTheme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';

const history = createBrowserHistory();
const store = configureStore(loadState());
// Customize what to save if needed
store.subscribe(() => {
  const globalState = store.getState();
  saveState({
    session: globalState.session
  });
});
loadInterceptor(store);

const App = () => {
  const { session } = store.getState();
  let theme = defaultTheme;
  if (session.loggedIn && session.user) {
    const { user } = session;
    const { shops = [] } = user;
    if (shops.length > 0) {
      const color = shops[0].styles?.primary_color;
      if (color) {
        theme = createMuiTheme({
          palette: {
            ...palette,
            primary: {
              contrastText: '#FFFFFF',
              main: color
            }
          },
          typography,
          overrides
        });
      }
    }
  }

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollReset />
            {renderRoutes(routes)}
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
