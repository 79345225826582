import { activityList } from 'api';
import { getErrorMessage } from 'utils/axios';

export const ACTIVITY_ERROR = 'ACTIVITY_ERROR';
export const ACTIVITY_LIST_REQUEST = 'ACTIVITY_LIST_REQUEST';
export const ACTIVITY_LIST_SUCCESS = 'ACTIVITY_LIST_SUCCESS';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
}

export const loadActivities = (type = '', handlers = defaultHandlers) => async (dispatch) => {
  const { onSuccess, onError } = handlers;
  // Init
  dispatch({ type: ACTIVITY_LIST_REQUEST });

  try {
    const res = await activityList(type);
    dispatch({
      type: ACTIVITY_LIST_SUCCESS,
      // Remove activities has null product or null type
      data: res.data.filter(item => Boolean(item.type && item.product))
    });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: ACTIVITY_ERROR, message });
    onError && onError(message);
  }
};
