import * as brandActions from 'actions/brandActions';

const initialState = {
  success: false,
  pending: true,
  error: '',
  // All brands data
  data: [],
  item: null,
};

const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case brandActions.BRANDS_LIST_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        data: []
      };
    case brandActions.BRANDS_ID_REQUEST:
    case brandActions.BRANDS_ADD_REQUEST:
    case brandActions.BRANDS_UPDATE_REQUEST:
    case brandActions.BRANDS_DELETE_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        item: null
      };
    case brandActions.BRANDS_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message
      };
    case brandActions.BRANDS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data
      };
    case brandActions.BRANDS_ID_SUCCESS:
    case brandActions.BRANDS_UPDATE_SUCCESS:
    case brandActions.BRANDS_ADD_SUCCESS:
    case brandActions.BRANDS_DELETE_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        item: action.data
      };
    default:
      return state;
  }
}

export default brandsReducer;
