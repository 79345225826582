import * as catalogActions from 'actions/catalogActions';

const initialState = {
  success: false,
  pending: true,
  error: '',
  // All catalog data
  data: []
};

const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case catalogActions.CATALOG_LIST_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        data: []
      };
    case catalogActions.CATALOG_ID_REQUEST:
    case catalogActions.CATALOG_ADD_REQUEST:
    case catalogActions.CATALOG_DELETE_REQUEST:
    case catalogActions.CATALOG_UPDATE_REQUEST:
      return {
        ...state,
        sucess: false,
        pending: true,
        item: null,
      };
    case catalogActions.CATALOG_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message
      };
    case catalogActions.CATALOG_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data
      };
    case catalogActions.CATALOG_ID_SUCCESS:
    case catalogActions.CATALOG_ADD_SUCCESS:
    case catalogActions.CATALOG_UPDATE_SUCCESS:
    case catalogActions.CATALOG_DELETE_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        item: action.data
      };
    default:
      return state
  }
}

export default brandsReducer;
