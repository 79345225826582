import { catalogList, catalogGet, catalogAdd, catalogEdit, catalogDelete } from 'api';
import { getErrorMessage } from 'utils/axios';

export const CATALOG_ERROR = 'CATALOG_ERROR';

export const CATALOG_LIST_REQUEST = 'CATALOG_LIST_REQUEST';
export const CATALOG_LIST_SUCCESS = 'CATALOG_LIST_SUCCESS';
export const CATALOG_ID_REQUEST = 'CATALOG_ID_REQUEST';
export const CATALOG_ID_SUCCESS = 'CATALOG_ID_SUCCESS';

export const CATALOG_ADD_REQUEST = 'CATALOG_ADD_REQUEST';
export const CATALOG_ADD_SUCCESS = 'CATALOG_ADD_SUCCESS';
export const CATALOG_UPDATE_REQUEST = 'CATALOG_UPDATE_REQUEST';
export const CATALOG_UPDATE_SUCCESS = 'CATALOG_UPDATE_SUCCESS';
export const CATALOG_DELETE_REQUEST = 'CATALOG_DELETE_REQUEST';
export const CATALOG_DELETE_SUCCESS = 'CATALOG_DELETE_SUCCESS';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

export const loadCatalogs = (handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { session } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: CATALOG_ERROR, message });
    onError && onError(message);
    return;
  }
  // Initialization
  dispatch({ type: CATALOG_LIST_REQUEST });

  // Fetch and set state based on fetch result
  try {
    const res = await catalogList(shop.id);
    dispatch({ type: CATALOG_LIST_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATALOG_ERROR, message: getErrorMessage(error) });
    onError && onError(message);
  }
};

export const addCatalog = (data, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { session } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: CATALOG_ERROR, message });
    onError && onError(message);
    return;
  }

  dispatch({ type: CATALOG_ADD_REQUEST });
  try {
    console.log(data);
    const res = await catalogAdd({
      ...data,
      shop: {
        _id: shop.id
      }
    });
    dispatch({ type: CATALOG_ADD_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    let message = getErrorMessage(error);
    if (error.response && error.response.status === 500) {
      message = 'SKU or slug already exists';
    }
    dispatch({ type: CATALOG_ERROR, message });
    onError && onError(message);
  }
};

export const getCatalog = (id, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: CATALOG_ID_REQUEST });

  const { categories } = getStore();
  const localCat = categories.data.find(item => item.id === id);
  if (localCat) {
    dispatch({ type: CATALOG_ID_SUCCESS, data: localCat });
    onSuccess && onSuccess();
    return;
  }

  try {
    const res = await catalogGet(id);
    dispatch({ type: CATALOG_ID_SUCCESS, data: res.data });
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATALOG_ERROR, message });
    onError && onError(message);
  }
};

export const updateCatalog = (id, data, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: CATALOG_UPDATE_REQUEST });

  try {
    // Edit pricing and update product, can be run parallel
    const res = await catalogEdit(id, data);

    dispatch({ type: CATALOG_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATALOG_ERROR, message });
    onError && onError(message);
  }
};

export const bulkDeleteCatalogs = (ids, handlers = defaultHandlers) => async dispatch => {
  if (ids.length < 1) {
    return;
  }
  const { onSuccess, onError } = handlers;
  dispatch({ type: CATALOG_DELETE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => catalogDelete(id)));
    dispatch({ type: CATALOG_DELETE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATALOG_ERROR, message });
    onError && onError(message);
  }
};

export const bulkMarkCatalogs = (ids, status, handlers = defaultHandlers) => async dispatch => {
  if (ids.length < 1) {
    return;
  }

  const { onSuccess, onError } = handlers;
  dispatch({ type: CATALOG_UPDATE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => catalogEdit(id, { isVisible: Boolean(status) })));
    dispatch({ type: CATALOG_UPDATE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATALOG_ERROR, message });
    onError && onError(message);
  }
};
