import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Popover, makeStyles, Button, TextField,
  MenuItem
} from '@material-ui/core';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useDispatch } from 'react-redux';

import { addFilesFilter, loadFiles } from 'actions/fileActions';
import { FILTER_OPERATORS, FILTER_OPTIONS } from '../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    margin: theme.spacing(3),
    width: '13em'
  },
  fieldsGroup: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const FilterPopover = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const [field, setField] = useState(Object.keys(FILTER_OPTIONS)[0]);
  const [operator, setOperator] = useState(Object.keys(FILTER_OPERATORS)[0]);
  const [date, setDate] = useState(moment());

  const handleFieldChange = (e) => setField(e.target.value);
  const handleOperatorChange = (e) => setOperator(e.target.value);

  const handleAddFilter = () => {
    dispatch(addFilesFilter(field, operator, date.toISOString()));
    dispatch(loadFiles());
  };

  const getFilterOptionsLabel = (key) => {
    switch (key) {
      case FILTER_OPTIONS.createdAt:
        return t('imageSelect.filter.field.options.createdAt');
      case FILTER_OPTIONS.updatedAt:
        return t('imageSelect.filter.field.options.updatedAt');
      default:
        return 'None';
    }
  };

  const getFilterOperatorsLabel = (key) => {
    switch (key) {
      case FILTER_OPERATORS.eq:
        return t('imageSelect.filter.operator.options.eq');
      case FILTER_OPERATORS.ne:
        return t('imageSelect.filter.operator.options.ne');
      case FILTER_OPERATORS.gt:
        return t('imageSelect.filter.operator.options.gt');
      case FILTER_OPERATORS.gte:
        return t('imageSelect.filter.operator.options.gte');
      case FILTER_OPERATORS.lt:
        return t('imageSelect.filter.operator.options.lt');
      case FILTER_OPERATORS.lte:
        return t('imageSelect.filter.operator.options.lte');
      default:
        return 'None';
    }
  }

  return (
    <Popover
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div className={classes.container}>
        <div className={classes.fieldsGroup}>
          <TextField
            variant="outlined"
            label={t('imageSelect.filter.field.label')}
            size="small"
            fullWidth
            select
            value={field}
            onChange={handleFieldChange}
          >
            {Object.keys(FILTER_OPTIONS).map((key) => (
              <MenuItem
                key={key}
                value={key}
              >
                {getFilterOptionsLabel(key)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            label={t('imageSelect.filter.operator.label')}
            size="small"
            fullWidth
            select
            value={operator}
            onChange={handleOperatorChange}
          >
            {Object.keys(FILTER_OPERATORS).map((key) => (
              <MenuItem
                key={key}
                value={key}
              >
                {getFilterOperatorsLabel(key)}
              </MenuItem>
            ))}
          </TextField>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            fullWidth
            size="small"
            inputVariant="outlined"
            format="DD/MM/yyyy"
            required
            value={date}
            InputAdornmentProps={{ position: 'start' }}
            onChange={setDate}
          />
        </div>
        <div className={classes.actions}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddFilter}
          >
            {t('imageSelect.filter.button.add')}
          </Button>
        </div>
      </div>
    </Popover>
  );
};
FilterPopover.propTypes = {
  className: PropTypes.string
};

export default FilterPopover;
