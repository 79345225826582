import axios from 'axios';
import getToken from 'api/getToken';
import { getErrorMessage } from 'utils/axios';

const API_URL = process.env.REACT_APP_API_ADDR;

export default function ImgUploader (editor) {
  console.log('Loading');
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;

    this.url = `${API_URL}/upload`
    this.cancelSource = axios.CancelToken.source();
  }

  // Starts the upload process.
  async upload() {
    let uploadFileRes;
    try {
      const fileRes = await this.loader.file;
      const data = new FormData();
      data.append('files', fileRes);

      const uploadRes = await axios.post(
        this.url, data,
        {
          cancelToken: this.cancelSource.token,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
      uploadFileRes = uploadRes.data[0];
    } catch (error) {
      if (axios.isCancel(error)) {
        throw 'Upload aborted';
      }

      throw getErrorMessage(error);
    }

    return {
      default: this._getImgURL(uploadFileRes),
    };
  }

  // Aborts the upload process.
  abort() {
    if (this.cancelSource) {
      this.cancelSource.cancel();
    }
  }

  _getImgURL(uploadData) {
    if (!uploadData) {
      return '';
    }

    let imgObj = uploadData;
    if (uploadData.formats) {
      // Run from large to small format
      const { large, medium, small } = uploadData.formats;
      if (large) {
        imgObj = large;
      } else if (medium) {
        imgObj = medium;
      } else if (small) {
        imgObj = small;
      }
    }

    return `${API_URL}${imgObj.url}`;
  }
}
