import * as prodActions from 'actions/productActions';

const PRODUCT_DEFAULT_OFFSET = 0;
const PRODUCT_DEFAULT_LIMIT = 5;

const initialState = {
  success: false,
  pending: false,
  error: '',

  page: PRODUCT_DEFAULT_OFFSET,
  pageSize: PRODUCT_DEFAULT_LIMIT,
  totalProducts: 0,
  query: '',
  data: [],
  // Because of the field input
  // Brands, tags, categories, catalogs use list of objects instead of string of ids
  filter: {
    brands: [],
    tags: [],
    categories: [],
    catalogs: [],
    price: [0, 0]
  },
  item: null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case prodActions.PRODUCT_ERROR:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.message
      };
    case prodActions.PRODUCT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case prodActions.PRODUCT_ADD_REQUEST:
    case prodActions.PRODUCT_ID_REQUEST:
    case prodActions.PRODUCT_UPDATE_REQUEST:
    case prodActions.PRODUCT_DELETE_REQUEST:
      return {
        ...state,
        pending: true,
        item: null
      };
    case prodActions.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        data: action.data,
        totalProducts: action.count
      };
    case prodActions.PRODUCT_ADD_SUCCESS:
    case prodActions.PRODUCT_ID_SUCCESS:
    case prodActions.PRODUCT_UPDATE_SUCCESS:
    case prodActions.PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        item: action.data
      };
    case prodActions.PRODUCT_CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      };
    case prodActions.PRODUCT_CHANGE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize
      };
    case prodActions.PRODUCT_SEARCH_QUERY:
      return {
        ...state,
        query: action.query
      };
    case prodActions.PRODUCT_SET_TAG_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          tags: action.tags
        }
      };
    case prodActions.PRODUCT_SET_BRAND_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          brands: action.brands
        }
      };
    case prodActions.PRODUCT_SET_PRICE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          price: [action.minValue, action.maxValue]
        }
      };
    case prodActions.PRODUCT_SET_CATEGORY_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          categories: action.categories
        }
      };
    case prodActions.PRODUCT_SET_CATALOG_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          catalogs: action.catalogs
        }
      };
    case prodActions.PRODUCT_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter
        }
      };
    default:
      return state;
  }
};

export default productReducer;
