/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import HomeIcon from '@material-ui/icons/HomeOutlined';
import DirectionsIcon from '@material-ui/icons/Directions';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ClassIcon from '@material-ui/icons/Class';
import BusinessIcon from '@material-ui/icons/Business';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import BookIcon from '@material-ui/icons/Book';
import RedeemIcon from '@material-ui/icons/Redeem';
import MessageIcon from '@material-ui/icons/Message';

// To translate using useTranslation hook, we will use the `key` field to indicate the translation key
// So that when calling t(menu.key) it will return the correct translation
export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Overview',
        key: 'overview',
        href: '/overview',
        icon: HomeIcon
      },
      /* {
        title: 'Dashboards',
        href: '/dashboards',
        icon: DashboardIcon,
        children: [
          {
            title: 'Default',
            href: '/dashboards/default'
          },
          {
            title: 'Analytics',
            href: '/dashboards/analytics'
          }
        ]
      },
      {
        title: 'Management',
        href: '/management',
        icon: BarChartIcon,
        children: [
          {
            title: 'Customers',
            href: '/management/customers'
          },
          {
            title: 'Customer Details',
            href: '/management/customers/1/summary'
          },
          {
            title: 'Projects',
            href: '/management/projects'
          },
          {
            title: 'Orders',
            href: '/management/orders'
          },
          {
            title: 'Order Details',
            href: '/management/orders/1'
          }
        ]
      }, */
      {
        title: 'Navigation',
        key: 'tags',
        href: '/navigation',
        icon: DirectionsIcon
      },
      {
        title: 'Categories',
        key: 'categories',
        href: '/categories',
        icon: ClassIcon
      },
      {
        title: 'Brands',
        key: 'brands',
        href: '/brands',
        icon: BusinessIcon
      },
      {
        title: 'Catalog',
        key: 'catalogs',
        href: '/catalog',
        icon: BookmarksIcon
      },
      {
        title: 'Product',
        key: 'products',
        href: '/product',
        icon: LocalMallIcon
      },
      {
        title: 'Promotions',
        key: 'promotions',
        href: '/promotions',
        icon: LoyaltyIcon
      },
      {
        title: 'Advertise',
        key: 'advertise',
        href: '/advertise',
        icon: RedeemIcon
      },
      {
        title: 'Orders',
        key: 'orders',
        href: '/orders',
        icon: ShoppingCartIcon
      },
      {
        title: 'Blogs',
        key: 'blogs',
        href: '/blog',
        icon: BookIcon
      },
      {
        title: 'Message',
        key: 'message',
        href: '/message',
        icon: MessageIcon
      },
      {
        title: 'Shop management',
        key: 'shop',
        href: '/shop',
        icon: StoreIcon
      },
      /* {
        title: 'Social Feed',
        href: '/social-feed',
        icon: PeopleIcon
      },
      {
        title: 'Profile',
        href: '/profile',
        icon: PersonIcon,
        children: [
          {
            title: 'Timeline',
            href: '/profile/1/timeline'
          },
          {
            title: 'Connections',
            href: '/profile/1/connections'
          },
          {
            title: 'Projects',
            href: '/profile/1/projects'
          },
          {
            title: 'Reviews',
            href: '/profile/1/reviews'
          }
        ]
      },
      {
        title: 'Project',
        href: '/projects',
        icon: FolderIcon,
        children: [
          {
            title: 'Browse',
            href: '/projects'
          },
          {
            title: 'Create',
            href: '/projects/create'
          },
          {
            title: 'Overview',
            href: '/projects/1/overview'
          },
          {
            title: 'Files',
            href: '/projects/1/files'
          },
          {
            title: 'Activity',
            href: '/projects/1/activity'
          },
          {
            title: 'Subscribers',
            href: '/projects/1/subscribers'
          }
        ]
      },
      {
        title: 'Invoice',
        href: '/invoices/1',
        icon: ReceiptIcon
      },
      {
        title: 'Kanban Board',
        href: '/kanban-board',
        icon: ListAltIcon
      },
      {
        title: 'Mail',
        href: '/mail',
        icon: MailIcon,
        label: () => (
          <Label
            color={colors.red[500]}
            shape="rounded"
          >
            2
          </Label>
        )
      },
      {
        title: 'Chat',
        href: '/chat',
        icon: ChatIcon,
        label: () => (
          <Label
            color={colors.red[500]}
            shape="rounded"
          >
            4
          </Label>
        )
      },
      {
        title: 'Calendar',
        href: '/calendar',
        icon: CalendarTodayIcon,
        label: () => <Label color={colors.green[500]}>New</Label>
      } */
    ]
  }
];
