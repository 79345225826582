import axios from 'axios';
import { logout } from 'actions/sessionActions';
import Qs from 'qs';

// Dont remove, some unused components use this
// Too lazy to remove
const instance = axios.create();

const globalConfigs = {
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: 'repeat' });
  }
};

const zaloInstance = axios.create({
  baseURL: 'https://openapi.zalo.me/v2.0',
  headers: {
    'Content-Type': 'application/json'
  },
  ...globalConfigs,
});
zaloInstance.interceptors.request.use(config => {
  config.params = {
    ...config.params,
    access_token: process.env.REACT_APP_ZALO_OA_TOKEN
  };

  return config;
}, null);

const request = (method, endpoint, token, data, headers = {}, params = {}) => {
  const authHeaders = {
    Authorization: `Bearer ${token}`
  };
  return axios({
    method,
    url: `${process.env.REACT_APP_API_ADDR}/${endpoint}`,
    data,
    params,
    headers: token ? {
      ...headers,
      ...authHeaders
    } : headers,
    ...globalConfigs
  });
};
// TODO: Adopt new request method instead of above one
const requestV2 = (method, endpoint, token, options = {}) => {
  const { headers = {}, ...rest } = options;
  const authHeaders = {
    Authorization: `Bearer ${token}`
  };

  return axios({
    ...rest,
    headers: token ? {
      ...headers,
      ...authHeaders
    } : headers,
    method,
    url: `${process.env.REACT_APP_API_ADDR}/${endpoint}`,
    ...globalConfigs
  });
};

const loadInterceptor = (store) => {
  axios.interceptors.response.use(
    response => {
      // console.log('Interceptor working');
      return response;
    },
    error => {
      if (error.response?.status === 401) {
        store.dispatch(logout());
      }
      return Promise.reject(error);
    }
  );
};

const getErrorMessage = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { message } = error.response.data;
    if (!message || message.length < 0) {
      return 'Unknown error';
    }

    const { messages } = message[0];
    // Optional chaining
    return messages?.[0]?.message || 'Unknown error'
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return 'No response from server. Try again later';
  } else {
    // Something happened in setting up the request that triggered an Error
    return `Error: ${error.message}`;
  }
};

export default instance;
export { request, requestV2, getErrorMessage, loadInterceptor, zaloInstance as zaloRequest };
