import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  TextField, Button, makeStyles, ButtonGroup, Tooltip, Menu, MenuItem, Typography
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SortIcon from '@material-ui/icons/Sort';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';

import {
  sortFilesBy, sortFilesOrder,
  searchFiles,
  loadFiles,
  changeFilePageSize
} from 'actions/fileActions';
import { FILE_SORT_BY } from 'utils/constants';
import { FilterPopover } from './components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  pageSizeChanger: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const ACTION_DELAY = 1500;

const ENTRIES_PER_PAGE_OPTS = [10, 20, 50, 100];

const Toolbar = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const filesState = useSelector(state => state.files);
  const { query, pageSize } = filesState;
  const sortAttr = filesState.sort.by;
  const sortAsc = filesState.sort.asc;

  const [search, setSearch] = useState(query);
  const [entries, setEntries] = useState(pageSize);
  const [sortAnchor, setSortAnchor] = useState(null);
  const [filterAnchor, setFilterAnchor] = useState(null);
  const searchTID = useRef(-1);
  const entriesTID = useRef(-1);
  
  useEffect(() => {
    return () => {
      clearTimeout(searchTID.current);
      clearTimeout(entriesTID.current);
    };
  }, [])

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearch(query);

    clearTimeout(searchTID.current);
    searchTID.current = setTimeout(() => {
      dispatch(searchFiles(query));
      dispatch(loadFiles());
    }, ACTION_DELAY);
  };

  const handleEntriesChange = (e) => {
    if (!e.target?.value) {
      return;
    }

    const entriesVal = parseInt(e.target.value);
    setEntries(entriesVal);

    clearTimeout(entriesTID.current);
    entriesTID.current = setTimeout(() => {
      dispatch(changeFilePageSize(entriesVal));
      dispatch(loadFiles());
    }, ACTION_DELAY);
  }

  const handleOpenSortMenu = (event) => {
    setSortAnchor(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setSortAnchor(null);
  };
  const handleChangeSortAttr = (attribute) => {
    setSortAnchor(null);
    dispatch(sortFilesBy(attribute));
    dispatch(loadFiles());
  };

  const handleChangeOrder = () => {
    dispatch(sortFilesOrder(!sortAsc));
    dispatch(loadFiles());
  };

  const getCurrentSortLabel = (attr) => {
    switch (attr) {
      case FILE_SORT_BY.UPDATE_DATE:
        return t('imageSelect.sort.byUpdate');
      case FILE_SORT_BY.UPLOAD_DATE:
        return t('imageSelect.sort.byDate');
      case FILE_SORT_BY.NAME:
        return t('imageSelect.sort.byName');
      default:
        return t('imageSelect.sort.none');
    }
  };

  const handleOpenFilterMenu = (event) => setFilterAnchor(event.currentTarget);
  const handleCloseFilterMenu = () => setFilterAnchor(null);

  return (
    <div
      className={clsx(className, classes.root)}
      {...rest}
    >
      <div className={classes.left}>
        <TextField
          variant="outlined"
          label={t('search.label')}
          placeholder={t('imageSelect.searchPlaceholder')}
          value={search}
          onChange={handleSearchChange}
          size="small"
        />
        <div className={classes.pageSizeChanger}>
          <TextField
            select
            value={entries}
            onChange={handleEntriesChange}
          >
            {ENTRIES_PER_PAGE_OPTS.map(itemCount => (
              <MenuItem
                key={itemCount}
                value={itemCount}
              >
                {itemCount}
              </MenuItem>
            ))}
          </TextField>
          <Typography
            variant="overline"
            color="textPrimary"
          >
            {t('imageSelect.pagination')}
          </Typography>
        </div>
      </div>
      <div className={classes.right}>
        <ButtonGroup
          size="small"
          variant="outlined"
          color="primary"
        >
          <Tooltip
            arrow
            title={t('imageSelect.tooltip.sortBy')}
          >
            <Button
              startIcon={<SortIcon />}
              onClick={handleOpenSortMenu}
            >
              {getCurrentSortLabel(sortAttr)}
            </Button>
          </Tooltip>
          <Tooltip
            arrow 
            title={t('imageSelect.tooltip.sortOrder')}
          >
            <Button onClick={handleChangeOrder}>
              {sortAsc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
            </Button>
          </Tooltip>
        </ButtonGroup>
        <Menu
          anchorEl={sortAnchor}
          open={Boolean(sortAnchor)}
          onClose={handleCloseSortMenu}
        >
          {Object.entries(FILE_SORT_BY).map(([key, value]) => (
            <MenuItem
              key={key}
              selected={sortAttr === value}
              onClick={() => handleChangeSortAttr(value)}
            >
              {getCurrentSortLabel(value)}
            </MenuItem>
          ))}
        </Menu>
        <Tooltip
          arrow
          title={t('imageSelect.tooltip.filter')}
        >
          <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<FilterListIcon />}
            onClick={handleOpenFilterMenu}
          >
            {t('imageSelect.button.filter')}
          </Button>
        </Tooltip>
        <FilterPopover
          anchorEl={filterAnchor}
          open={Boolean(filterAnchor)}
          onClose={handleCloseFilterMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      </div>
    </div>
  )
};
Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
