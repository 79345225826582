import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {}
}));

const NewButton = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation(['common']);

  return (
    <Button
      {...rest}
      variant="contained"
      color="primary"
      className={clsx(classes.root, className)}
      startIcon={<AddIcon />}
    >
      {t('button.add')}
    </Button>
  );
};
NewButton.propTypes = {
  className: PropTypes.string
};

export default NewButton;
