import slugify from 'slugify';

// Replace original dj (DJ) with d (D)
const charMap = {
  'đ': 'd',
  'Đ': 'D'
};

slugify.extend(charMap);

const customSlugify = (string) => {
  return slugify(string, {
    strict: true,
    lower: true,
  });
};

export default customSlugify;

export const customHashify = (string) =>
  slugify(string, {
    lower: true,
    strict: true,
    replacement: '_'
  });
