import { transactionAdd, transactionGet, transactionList } from 'api';
import { getErrorMessage } from 'utils/axios';

export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';

export const TRANSACTION_LIST_REQUEST = 'TRANSACTION_LIST_REQUEST';
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS';

export const TRANSACTION_ID_REQUEST = 'TRANSACTION_ID_REQUEST';
export const TRANSACTION_ID_SUCCESS = 'TRANSACTION_ID_SUCCESS';

export const TRANSACTION_ADD_REQUEST = 'TRANSACTION_ADD_REQUEST';
export const TRANSACTION_ADD_SUCCESS = 'TRANSACTION_ADD_SUCCESS';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

// TODO Extend to other transaction type
export const loadTransactions = (type = 'MESSENGER') => async (dispatch) => {
  // Initialization
  dispatch({ type: TRANSACTION_LIST_REQUEST });

  // Fetch and set state based on fetch result
  try {
    const res = await transactionList({ type });
    dispatch({ type: TRANSACTION_LIST_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: TRANSACTION_ERROR, message: getErrorMessage(error) });
  }
};

export const getTransaction = (id, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: TRANSACTION_ID_REQUEST });

  const { transactions } = getStore();
  const localTransaction = transactions.data.find(item => item.id === id);
  if (localTransaction) {
    dispatch({ type: TRANSACTION_ID_SUCCESS, data: localTransaction });
    onSuccess && onSuccess();
    return;
  }

  try {
    const res = await transactionGet(id);
    dispatch({ type: TRANSACTION_ID_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch({ type: TRANSACTION_ERROR, message });
    onError && onError(message);
  }
};

export const addTransaction = (data, handlers = defaultHandlers) => async (dispatch) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: TRANSACTION_ADD_REQUEST });

  try {
    const res = await transactionAdd(data);
    dispatch({ type: TRANSACTION_ADD_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch({ type: TRANSACTION_ERROR, message });
    onError && onError(message);
  }
};
