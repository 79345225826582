import * as activityActions from 'actions/activityActions';

const initialState = {
  success: false,
  pending: true,
  error: '',
  data: []
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityActions.ACTIVITY_ERROR:
      return {
        ...state,
        pending: false,
        success: false,
        error: action.message
      };
    case activityActions.ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        pending: true,
        success: false,
        data: []
      };
    case activityActions.ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        data: action.data
      };
    default:
      return state;
  }
};

export default activityReducer;
