import React from 'react';
import {
  Chip, makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { deleteFilesFilter, loadFiles } from 'actions/fileActions';
import { FILTER_OPERATORS, FILTER_OPTIONS } from '../Toolbar/components/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const FiltersList = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const filters = useSelector(state => state.files.filters);

  const handleRemoveFilter = (id) => {
    dispatch(deleteFilesFilter(id));
    dispatch(loadFiles());
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      {filters.map(filter => {
        const fieldLabel = FILTER_OPTIONS[filter.field];
        const opLabel = FILTER_OPERATORS[filter.op];
        const dateFmt = moment(filter.value).format('DD/MM/YYYY');

        return (
          <Chip
            className={classes.chip}
            key={filter.id}
            color="primary"
            label={
              <div>
                {fieldLabel}{' '}
                <b>{opLabel}</b>
                {' '}{dateFmt}
              </div>
            }
            onDelete={() => handleRemoveFilter(filter.id)}
          />
        );
      })}
    </div>
  );
};
FiltersList.propTypes = {
  className: PropTypes.string
};

export default FiltersList;
