import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {},
  summaryButton: {
    backgroundColor: theme.palette.white
  },
  barChartIcon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: '100%',
    maxHeight: 400
  }
}));

const Header = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation('overview');
  const session = useSelector(state => state.session);

  const currentHours = new Date().getHours();
  let greeting = 'Good day to you';
  if (currentHours < 13) {
    greeting = t('greeting.morning');
  } else if (currentHours < 19) {
    greeting = t('greeting.afternoon');
  } else if (currentHours <= 23) {
    greeting = t('greeting.evening');
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            {t('header')}
          </Typography>
          <Typography
            component="h1"
            gutterBottom
            variant="h3"
          >
            {greeting}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
          >
            {session.user?.fullName}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            md={6}
          >
            <img
              alt="Cover"
              className={classes.image}
              src="/images/undraw_growth_analytics_8btt.svg"
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
