import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

import Alert from './components/Alert';

const Toast = (props) => {
  const {
    children, severity, onClose, duration, ...rest
  } = props;
  return (
    <Snackbar
      onClose={onClose}
      autoHideDuration={duration}
      {...rest}
    >
      <Alert
        onClose={onClose}
        severity={severity}
      >
        {children}
      </Alert>
    </Snackbar>
  );
}
Toast.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  duration: PropTypes.number,
  severity: PropTypes.string
};
Toast.defaultProps = {
  children: null,
  onClose: () => {},
  duration: 3000,
  severity: 'info'
};

export default Toast;
