import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useSelector, } from 'react-redux';
import { Card, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PROMOTION_TYPE } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  }
}));

const Statistics = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation('overview');

  const orders = useSelector(state => state.orders.data);
  const totalProducts = useSelector(state => state.products.totalProducts);

  const currencyFormatter = new Intl.NumberFormat('vi', {
    style: 'currency',
    currency: 'VND'
  });

  const totalRevenue = useMemo(() => orders.reduce((sum, order) => {
    const total = order.items.reduce((acc, line) => {
      let totalPrice = line.quantity * line.price;
      if (line.promotion) {
        switch (line.promotion.type) {
          case PROMOTION_TYPE.P_PERCENTAGE.code: {
            const discount = parseInt(line.promotion.value) || 0;
            totalPrice = totalPrice * (1.0 - (discount / 100));
            break;
          }
          default:
            break;
        }
      }

      return acc + totalPrice;
    }, 0);

    return sum + total;
  }, 0), [orders]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
      >
        <Grid
          className={classes.item}
          item
          md={4}
          sm={6}
          xs={12}
        >
          <Typography variant="h2">{currencyFormatter.format(totalRevenue)}</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            {t('statistics.totalRevenue')}
          </Typography>
        </Grid>
        <Grid
          className={classes.item}
          item
          md={4}
          sm={6}
          xs={12}
        >
          <Typography variant="h2">{totalProducts}</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            {t('statistics.totalProduct')}
          </Typography>
        </Grid>
        <Grid
          className={classes.item}
          item
          md={4}
          sm={6}
          xs={12}
        >
          <Typography variant="h2">{orders.length}</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            {t('statistics.totalOrder')}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
