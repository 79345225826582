import {
  categoriesList,
  categoriesGet,
  categoriesAdd,
  categoriesEdit,
  categoriesDelete
} from 'api';
import { getErrorMessage } from 'utils/axios';

export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';

export const CATEGORIES_LIST_REQUEST = 'CATEGORIES_LIST_REQUEST';
export const CATEGORIES_LIST_SUCCESS = 'CATEGORIES_LIST_SUCCESS';

export const CATEGORIES_ID_REQUEST = 'CATEGORIES_ID_REQUEST';
export const CATEGORIES_ID_SUCCESS = 'CATEGORIES_ID_SUCCESS';

export const CATEGORIES_ADD_REQUEST = 'CATEGORIES_ADD_REQUEST';
export const CATEGORIES_ADD_SUCCESS = 'CATEGORIES_ADD_SUCCESS';

export const CATEGORIES_DELETE_REQUEST = 'CATEGORIES_DELETE_REQUEST';
export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIES_DELETE_SUCCESS';

export const CATEGORIES_UPDATE_REQUEST = 'CATEGORIES_UPDATE_REQUEST';
export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIES_UPDATE_SUCCESS';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
};

export const loadCategories = (handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { session } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: CATEGORIES_ERROR, message });
    onError && onError(message);
    return;
  }
  // Initialization
  dispatch({ type: CATEGORIES_LIST_REQUEST });

  // Fetch and set state based on fetch result
  try {
    const res = await categoriesList(shop.id);
    dispatch({ type: CATEGORIES_LIST_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATEGORIES_ERROR, message: getErrorMessage(error) });
    onError && onError(message);
  }
};

export const addCategory = (data, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { session } = getStore();
  const { onSuccess, onError } = handlers;

  const shop = session.shopIndex < 0 ? null : session.shops[session.shopIndex];
  if (!shop) {
    const message = 'Missing shop';
    dispatch({ type: CATEGORIES_ERROR, message });
    onError && onError(message);
    return;
  }

  dispatch({ type: CATEGORIES_ADD_REQUEST });
  try {
    const res = await categoriesAdd({
      ...data,
      shop: {
        _id: shop.id
      }
    });
    dispatch({ type: CATEGORIES_ADD_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATEGORIES_ERROR, message });
    onError && onError(message);
  }
};

export const getCategory = (id, handlers = defaultHandlers) => async (dispatch, getStore) => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: CATEGORIES_ID_REQUEST });
  
  const { categories } = getStore();
  const localCat = categories.data.find(item => item.id === id);
  if (localCat) {
    dispatch({ type: CATEGORIES_ID_SUCCESS, data: localCat });
    onSuccess && onSuccess();
    return;
  }

  try {
    const res = await categoriesGet(id);
    dispatch({ type: CATEGORIES_ID_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATEGORIES_ERROR, message });
    onError && onError(message);
  }
};

export const updateCategory = (id, data, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  dispatch({ type: CATEGORIES_UPDATE_REQUEST });

  try {
    // Edit pricing and update product, can be run parallel
    const res = await categoriesEdit(id, data);

    dispatch({ type: CATEGORIES_UPDATE_SUCCESS, data: res.data });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATEGORIES_ERROR, message });
    onError && onError(message);
  }
};

export const bulkDeleteCategories = (ids, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  if (ids.length < 1) {
    return;
  }
  dispatch({ type: CATEGORIES_DELETE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => categoriesDelete(id)));
    dispatch({ type: CATEGORIES_DELETE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATEGORIES_ERROR, message });
    onError && onError(message);
  }
};

export const bulkMarkCategories = (ids, status, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;
  if (ids.length < 1) {
    return;
  }

  dispatch({ type: CATEGORIES_UPDATE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => categoriesEdit(id, { isVisible: Boolean(status) })));
    dispatch({ type: CATEGORIES_UPDATE_SUCCESS, data: res.map(r => r.data) });
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: CATEGORIES_ERROR, message });
    onError && onError(message);
  }
};
