import { fileList, fileUpload, fileDelete, fileCount } from 'api';
import { customHashify } from 'utils/slugify';
import { getErrorMessage } from 'utils/axios';
import { v1 as uuid } from 'uuid';

export const FILE_ERROR = 'FILE_ERROR';

export const FILE_LIST_REQUEST = 'FILE_LIST_REQUEST';
export const FILE_LIST_SUCCESS = 'FILE_LIST_SUCCESS';

export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';

export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';

export const FILE_PAGE_CHANGE = 'FILE_PAGE_CHANGE';
export const FILE_PAGE_SIZE_CHANGE = 'FILE_PAGE_SIZE_CHANGE';

export const FILE_SEARCH_QUERY = 'FILE_SEARCH_QUERY';
export const FILE_CHANGE_SORT_TYPE = 'FILE_CHANGE_SORT_TYPE';
export const FILE_CHANGE_SORT_ORDER = 'FILE_CHANGE_SORT_ORDER';

export const FILE_ADD_FILTER = 'FILE_ADD_FILTER';
export const FILE_REMOVE_FILTER = 'FILE_REMOVE_FILTER';

const defaultHandlers = {
  onSuccess: () => {},
  onError: () => {}
}

export const loadFiles = () => async (dispatch, getStore) => {
  const { files } = getStore();
  const { page, pageSize, query, sort, filters } = files;
  const { by: sortBy, asc: sortAscend } = sort;

  dispatch({ type: FILE_LIST_REQUEST });

  const params = {
    _sort: `${sortBy}:${sortAscend ? 'asc' : 'desc'}`,
  };
  if (query) {
    params.hash_contains = customHashify(query);
  }
  for (const filter of filters) {
    const key = `${filter.field}_${filter.op}`;
    params[key] = filter.value;
  }

  try {
    const [fileRes, countRes] = await Promise.all([
      fileList(page * pageSize, pageSize, params),
      fileCount(params)
    ]);
    dispatch({
      type: FILE_LIST_SUCCESS,
      data: fileRes.data,
      count: countRes.data.count || 0
    });
  } catch (error) {
    dispatch({ type: FILE_ERROR, message: getErrorMessage(error) });
  }
};

export const changeFilePage = (page) => async dispatch => {
  dispatch({ type: FILE_PAGE_CHANGE, page });
  await dispatch(loadFiles());
};

export const changeFilePageSize = (pageSize) => dispatch => {
  dispatch({ type: FILE_PAGE_SIZE_CHANGE, pageSize });
};

export const uploadFiles = (formData) => async dispatch => {
  dispatch({ type: FILE_UPLOAD_REQUEST });

  try {
    const res = await fileUpload(formData);
    dispatch({ type: FILE_UPLOAD_SUCCESS, data: res.data });
  } catch (error) {
    dispatch({ type: FILE_ERROR, message: getErrorMessage(error) });
  }
};

export const bulkDeleteFiles = (ids, handlers = defaultHandlers) => async dispatch => {
  const { onSuccess, onError } = handlers;

  dispatch({ type: FILE_DELETE_REQUEST });

  try {
    const res = await Promise.all(ids.map(id => fileDelete(id)));
    dispatch({ type: FILE_DELETE_SUCCESS, data: res.map(r => r.data )});
    onSuccess && onSuccess();
  } catch (error) {
    const message = getErrorMessage(error);
    dispatch({ type: FILE_ERROR, message });
    onError && onError(message);
  }
};

export const searchFiles = (query) => dispatch => {
  dispatch({ type: FILE_SEARCH_QUERY, query });
};

export const sortFilesBy = (attribute) => dispatch => {
  dispatch({ type: FILE_CHANGE_SORT_TYPE, attribute });
};

export const sortFilesOrder = (ascending = true) => dispatch => {
  dispatch({ type: FILE_CHANGE_SORT_ORDER, asc: ascending });
};

export const addFilesFilter = (field, op, value) => dispatch => {
  const filter = {
    id: uuid(),
    field, op, value
  };
  dispatch({ type: FILE_ADD_FILTER, filter });
};

export const deleteFilesFilter = (id) => dispatch => {
  dispatch({ type: FILE_REMOVE_FILTER, id });
};
