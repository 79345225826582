import * as actionTypes from 'actions';

const initialState = {
  error: '',
  success: false,
  pending: false,

  loggedIn: false,
  user: null,
  token: '',
  shops: [],
  shopIndex: -1,

  lang: 'vi'
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_ERROR: {
      return {
        ...state,
        error: action.message,
        success: false,
        pending: false,
      };
    }
    case actionTypes.SESSION_LOGIN: {
      return {
        loggedIn: true,
        user: action.data.user,
        shops: action.data.user.shops || [],
        token: action.data.jwt,
        shopIndex: 0
      };
    }
    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: null,
        shops: [],
        token: '',
        shopIndex: -1
      };
    }
    case actionTypes.SESSION_SHOP_UPDATE_REQUEST: {
      return {
        ...state,
        pending: true
      };
    }
    case actionTypes.SESSION_SHOP_UPDATE_SUCCESS: {
      if (state.shopIndex < 0) {
        return state;
      }

      const newShops = [...state.shops];
      newShops[state.shopIndex] = {
        ...action.data
      };

      return {
        ...state,
        pending: false,
        success: true,
        shops: newShops
      };
    }
    case actionTypes.SESSION_SHOP_CHANGE: {
      const idx = state.shops.findIndex(item => item.id === action.id);
      if (idx < 0) {
        return state;
      }

      return {
        ...state,
        shopIndex: idx
      };
    }
    case actionTypes.SESSION_LANGUAGE_CHANGE:
      switch (action.language) {
        case 'en':
          return {
            ...state,
            lang: 'en'
          };
        default:
          return {
            ...state,
            lang: 'vi'
          };
      }
    default: {
      return state;
    }
  }
};

export default sessionReducer;
