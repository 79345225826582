import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Slider from 'react-slick';

const API_URL = process.env.REACT_APP_API_ADDR;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .slick-slider .slick-slide': {
      position: 'relative'
    },
    '& .slick-slider .slick-arrow::before': {
      color: theme.palette.primary.main
    },
    '& .slick-track': {
      // Horizontal centering all images
      display: 'flex',
      alignItems: 'center'
    }
  },
  img: {
    maxWidth: '100%',
    maxHeight: '150px',
    textAlign: 'center',
    width: 'auto',
    verticalAlign: 'middle',
    margin: 0,
    padding: 0,
  }
}));

const ImagesPreview = (props) => {
  const { images, className, ...rest } = props;
  if (!images || images.length < 1) {
    return null;
  }

  const classes = useStyles();

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(images.length, 3),
    slidesToScroll: 1,
    adaptiveHeight: false,
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Slider
        {...settings}
        {...rest}
      >
        {images.map(item => {
          // Get resized image, fallback with original image
          let image = item;
          const { large, medium } = item.formats || {};
          if (medium) {
            image = medium;
          } else if (large) {
            image = large;
          }

          return (
            <div key={item.id}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={`${API_URL}${image.url}`}
                  alt={item.name}
                  className={classes.img}
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
ImagesPreview.propTypes = {
  images: PropTypes.array,
  className: PropTypes.string
};
ImagesPreview.defaultProps = {
  images: []
};

export default ImagesPreview;
