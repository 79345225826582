import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.white,
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[900]
    }
  }
}));

const SaveButton = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation('common')

  return (
    <Button
      {...rest}
      variant="contained"
      className={clsx(classes.root, className)}
      startIcon={<SaveIcon />}
    >
      {t('button.save')}
    </Button>
  );
};
SaveButton.propTypes = {
  className: PropTypes.string
};

export default SaveButton;
