import { request, requestV2 } from 'utils/axios';
import getToken from './getToken';

const ENDPOINT = 'catalogs';

export const list = (shopId, parameters = {}) => {
  const params = {
    ...parameters,
    isDeleted: false,
    _sort: 'position:asc'
  };
  if (shopId) {
    params.shop = shopId;
  }

  return requestV2('get', ENDPOINT, undefined, { params });
}

export const get = (id) => request(
  'get',
  `${ENDPOINT}/${id}`
);

export const add = (data) => request('post', `${ENDPOINT}`, getToken(), data);

export const edit = (id, data) => request('put', `${ENDPOINT}/${id}`, getToken(), data);

export const remove = (id) => request('delete', `${ENDPOINT}/${id}`, getToken());

export const count = (slug) => request('get', `${ENDPOINT}/count?${slug ? `slug=${slug}` : ''}`);
